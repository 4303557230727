'use strict';

function removeLastWord(str) {
    const trimmedString = str.trimRight();
    const indexOfLastWord = trimmedString.lastIndexOf(' ');
    return str.substring(0, indexOfLastWord);
}

module.exports = {
    removeLastWord: removeLastWord
}
