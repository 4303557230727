'use strict';

var tabs = require('core/components/tabs');

tabs.checkLinkedProductTabs = function () {
    var tabLinks = document.querySelectorAll('[data-toggle=tab]');

    [].forEach.call(tabLinks, function(tabLink) {
        var tabContent = document.querySelector(tabLink.getAttribute('href')),
            tabProducts = tabContent.querySelector('[class*=productLinked]');
        
        if (tabProducts) {
            var products = tabProducts.querySelector('.product-tile');
            
            if (!products) {
                tabLink.parentElement.classList.add('d-none');
            }
        }
    });
}

module.exports = tabs;
