'use strict';

/**
* Overriding from base SFRA to fix nested collapsibles
*/

/**
 * toggleDrawer - opens / closes pdpCards drawer with animation to height property via CSS transition
 * function callculates height of cardBody Child Elements (which are hidden via overflow property)
 * then adds height to body, thus animating from CSS transition properties
 * @param {*} direction - string - 'open' or 'close'
 * @param {*} scope - active Card element
 */
function toggleDrawer(direction, scope) {
    var cardTitle = scope.querySelector('.card-title'),
        cardBody = scope.querySelector('.card-body'),
        cardUl = scope.querySelector('.pdp-detail-attribute-list'),
        cardUlStyles,
        totalHeight = 0;

    if (direction === 'open') {
        scope.classList.add('active');
        cardTitle.setAttribute('aria-expanded', true);

        cardUlStyles = window.getComputedStyle(cardUl);
        totalHeight = cardUl.getBoundingClientRect().height + parseInt(cardUlStyles.marginBottom, 10);

        cardBody.style.height = totalHeight + 'px';

    } else if(direction === 'close') {
        // save close function as variable so we can remove it after transitionEnd (necessary for smooth animation on close)
        var removeHeightAfterTransition = function(event) {
            if (event.propertyName === 'height') {
                scope.classList.remove('active');
                cardTitle.setAttribute('aria-expanded', false);
            }
            cardBody.removeEventListener('transitionend',removeHeightAfterTransition);
         };

         cardBody.style.height = '0px';
         cardBody.addEventListener('transitionend', removeHeightAfterTransition, false);
    }
}

module.exports = function () {
    var sizes = ['xs', 'sm', 'md', 'lg', 'xl'];

    sizes.forEach(function (size) {
        var selector = '.collapsible-' + size + ' .title';
        $('body').on('click', selector, function (e) {
            e.preventDefault();

            // check if target is part of PDP cards
            var pdpCards = e.target.closest('.pdp-cards');
            // if so, open via ToggleDrawer animation
            if (pdpCards) {
                // PDP Collapsible Events 
                var activeCard = this.closest('.card');
                // if target is active, close it and fn comes to end
                if (activeCard.classList.contains('active')) {
                    toggleDrawer('close', activeCard);
                } else {
                    var oldActiveCard = pdpCards.querySelector('.active');
                    // hide any previously active card
                    if (oldActiveCard) {
                        toggleDrawer('close', oldActiveCard);
                    }
                    // show active card
                    toggleDrawer('open', activeCard);
                }
            } else {
                // Normal Collapsible Events 
                $(this).closest('.collapsible-' + size).toggleClass('active');

                if ($(this).closest('.collapsible-' + size).hasClass('active')) {
                    $(this).attr('aria-expanded', true);
                } else {
                    $(this).attr('aria-expanded', false);
                }
            }
        });
    });
};