'use strict';

const header = require("../components/header");

// Check if target is back btn, or if target is child of back btn
function isTargetBackBtn(target) {
    return target.classList.contains('active-category-back-btn') || target.closest('.active-category-back-btn');
}
    
// Function to get the depth of a <ul> list and add data-menu-level to each <li> with nested <ul>
function getListDepthAndSetMenuLevel(ul, level = 1) {
    // Start with the current level as the initial max depth
    var maxDepth = level;

    // Loop through each <li> in the <ul>
    ul.querySelectorAll(':scope > li').forEach((li) => {
        // Check if <li> has a nested <ul>
        var nestedUl = li.querySelector(':scope > ul');
        
        if (nestedUl) {
            // Set the data attribute for the current level on this <li>
            li.setAttribute('data-menu-level', level);
            // Recursively get the depth of the nested <ul> and add 1 for the next level
            var depth = getListDepthAndSetMenuLevel(nestedUl, level + 1);
            // Update maxDepth if the nested depth is greater
            maxDepth = Math.max(maxDepth, depth);
        }
    });

    return maxDepth;
}

function updateLinkHref(activeCategoryLabel, navLink) {
    if (navLink && !navLink.hasAttribute('data-disable-view-all')) {
        activeCategoryLabel.href = navLink.href;
        activeCategoryLabel.classList.add('active-category-href');
    } else {
        activeCategoryLabel.href = '#';
        activeCategoryLabel.classList.remove('active-category-href');
    }
}

// dispatches custom menu even, adds 'menuLevel', 'oldLevel', and trigger to event.datail data
function dispatchCustomMenuUpdateEvent(trigger) {   
    var localNavbar = trigger.closest('.navbar'),
        localMobileNav = localNavbar ? localNavbar.querySelector('[data-current-active-level]') : null;

    if (localMobileNav) {
        var oldLevel = parseInt(localMobileNav.getAttribute('data-current-active-level'), 10),
            menuLevel = trigger.hasAttribute('data-menu-level') ? trigger.getAttribute('data-menu-level') : oldLevel - 1,
            menuUpdateEvent = new CustomEvent('menuLevelUpdate', {
                detail: {
                    menuLevel: menuLevel,
                    oldLevel: oldLevel,
                    trigger: trigger
                }
            });
        // menuLevel should never be less than zero
        if (menuLevel >= 0) {
            document.dispatchEvent(menuUpdateEvent);
        }
    }
}

// resets menu to '0', on main menu close, menu needs to be a root
function clearMenuLevelToZero(mobileMenuContainer) {
    if (mobileMenuContainer) {
        var localActiveCategoryContainer = mobileMenuContainer.querySelector('[data-current-active-category]'),
            localMobileNav = mobileMenuContainer.querySelector('[data-current-active-level]');

        // when mobileMenuContainer ends transition, reset menu level to 0
        mobileMenuContainer.addEventListener('transitionend', (event) => {
            if (event.propertyName === 'transform' || event.propertyName === 'opacity') {
                if (localActiveCategoryContainer) {
                    localActiveCategoryContainer.setAttribute('data-current-active-category', 0)
                }
                if (localMobileNav) {
                    localMobileNav.setAttribute('data-current-active-level', 0)
                    localMobileNav.style.transform = 'translateX(0%)';
                }
            }
        }, { once: true });
    }
}

// Listens for the custom event and updates main menu per users interaction
var menuLevelUpdateListener = function menuLevelUpdateListener(event) {
    var menuLevel = 'menuLevel' in event.detail ? event.detail.menuLevel : null,
        isBackBtn = isTargetBackBtn(event.detail.trigger),
        localNavbar = event.detail.trigger.closest('.navbar'),
        localMobileNav = localNavbar.querySelector('[data-current-active-level]'),
        localActiveCategoryContainer = localNavbar.querySelector('[data-current-active-category]'),
        localActiveCategoryLabel = localActiveCategoryContainer ? localActiveCategoryContainer.querySelector('.active-category') : null,
        isBurger = event.detail.trigger.closest('.burger-menu') ? true : false;
    
    // set current active nav level on mobile menu and activeCategoryLabel
    if (localMobileNav) {
        localMobileNav.setAttribute('data-current-active-level', menuLevel);
    }

    if (localActiveCategoryContainer) {
        localActiveCategoryContainer.setAttribute('data-current-active-category', menuLevel);
    }
    
    if (window.isMobile() || isBurger) {
        if (menuLevel !== null) {

            // animate mobile nav
            localMobileNav.style.transform = 'translateX(' + -100 * menuLevel + '%)';
            
            // menu was clicked
            if (event.detail.trigger.hasAttribute('data-menu-level')) {
                var triggerLink = event.detail.trigger.querySelector('[data-toggle="dropdown"]'),
                    navLink = event.detail.trigger.querySelector('.nav-link') || event.detail.trigger.querySelector('.dropdown-link'),
                    siblings = Array.from(event.detail.trigger.parentNode.children).filter(element => element.classList.contains('active') && element !== event.detail.trigger);
                // Get all siblings of the active element and remove active class
                [].forEach.call(siblings, function(sib) {
                    sib.classList.remove('active');
                });
                
                // add active class
                event.detail.trigger.classList.add('active');
                // if navLink, get text content to update current-active-categroy-label
                if (navLink && localActiveCategoryLabel) {
                    // update active category label
                    localActiveCategoryLabel.textContent = navLink.textContent;
                }

                if (localActiveCategoryLabel) {
                    // check for disableViewAll on trigger link, if not there, add href to active category label
                    updateLinkHref(localActiveCategoryLabel, triggerLink);
                }
            } else if (event.detail.trigger.classList.contains('active-category') || isBackBtn) {
                // active category (back button clicked) // get active parent li
                var activeEl = localMobileNav.querySelector(`[data-menu-level="${event.detail.oldLevel}"].active`),
                    newActiveEl = localMobileNav.querySelector(`[data-menu-level="${event.detail.menuLevel}"].active`);

                if (activeEl) {
                    // update active category label
                    if (menuLevel === 0) {
                        if (localActiveCategoryLabel) {
                            localActiveCategoryLabel.textContent = '';
                        }
                    } else {
                        if (newActiveEl) {
                            var navLink = newActiveEl.querySelector('.nav-link');
                            if (navLink) {
                                if (localActiveCategoryLabel) {
                                    localActiveCategoryLabel.textContent = navLink.textContent;
                                    updateLinkHref(localActiveCategoryLabel, navLink);
                                }
                            }
                        }
                    }
                    
                    // dont remove active class on parent element until transition end is complete, only fires once per {once: true} param
                    localMobileNav.addEventListener('transitionend', (event) => {
                        if (event.propertyName === 'transform') {
                            activeEl.classList.remove('active');
                        }
                    }, { once: true });
                }
            }
        }
    }
}

// set menu levels on any uls deeply nested inside .menu-categories ul, 
function setupMenuLevels() {
    var lev1 =  document.querySelectorAll('[data-menu-level="1"] > ul'),
        // lev2 are nested inside megamenu templates
        lev2 =  document.querySelectorAll('[data-menu-level="2"] > ul');

    [].forEach.call(lev1, function(ul) {
        getListDepthAndSetMenuLevel(ul, 2);
    });

    [].forEach.call(lev2, function(ul) {
        getListDepthAndSetMenuLevel(ul, 3);
    });
}

// gets logo styles from .logo-home in the header, then updates [data-mobile-nav-logo] with appropriate styles
// will work across all sites applied to
function addLogoToMenu() {
    var logoContainers = document.querySelectorAll('[data-mobile-nav-logo]'),
        logoHome = document.querySelector('.logo-home');

    [].forEach.call(logoContainers, function(logoContainer) {
        var logoHomeStyles = window.getComputedStyle(logoHome),
            logoBackground = logoHomeStyles.getPropertyValue('background'),
            logoWidth = parseInt(logoHomeStyles.getPropertyValue('width'), 10),
            logoHeight = parseInt(logoHomeStyles.getPropertyValue('height'), 10),
            aspRatio = logoWidth / logoHeight,
            newLogoWidth = parseInt(aspRatio * 46, 10) + 'px';

        logoContainer.style.background = logoBackground;
        logoContainer.style.width = newLogoWidth;
        // todo - where does this height style come from?
        logoContainer.style.height = '46px';
    });
    
}

// set BurgerMenuTop Style Value to enure it matches with any
// header scenario (fixed, fixed-enhanced, static)
function setBurgerMenuTop() {
    var burgerMenu = document.querySelector('.burger-menu-citizen'),
        headerNav = document.querySelector('#header-nav');

    if (burgerMenu && headerNav) {
        if (headerNav.getBoundingClientRect().top >= 0) {
            burgerMenu.style.top = headerNav.getBoundingClientRect().top + 'px';
        }
    }
}

function init() {
    var mobileNavs = document.querySelectorAll('[data-current-active-level]');

    if (mobileNavs.length > 0) {
        // set up data-menu-level atts
        setupMenuLevels();

        // add logo to mobile menu
        addLogoToMenu();

        // menuLevelUpdate is custom event emited when mobile nav is interacted with
        document.addEventListener('menuLevelUpdate', menuLevelUpdateListener);

        [].forEach.call(mobileNavs, function(mobileNav) {
            var localNavbar = mobileNav.closest('.navbar');

            if (localNavbar) {
                var localActiveCategoryContainer = localNavbar.querySelector('[data-current-active-category]'),
                    localActiveCategoryLabel = localNavbar.querySelector('.active-category');

                if (localNavbar) {
                    // active category label event listener
                    localActiveCategoryContainer.addEventListener('click', function(e) {
                        var target = e.target,
                            isBackBtn = isTargetBackBtn(e.target);

                        // only dispatch event if active cat does not have an href, or if the back btn was clicked
                        if (!localActiveCategoryLabel.classList.contains('active-category-href') || isBackBtn) {
                            if (isBackBtn) {
                                dispatchCustomMenuUpdateEvent(target);
                            } else {
                                dispatchCustomMenuUpdateEvent(localActiveCategoryLabel);
                            }
                            e.preventDefault();
                        }
                    });
                }
            }
        });
    }
}

module.exports = {
    init: init,
    dispatchCustomMenuUpdateEvent: dispatchCustomMenuUpdateEvent,
    clearMenuLevelToZero: clearMenuLevelToZero,
    setBurgerMenuTop: setBurgerMenuTop
}
