'use strict';

// helper functions for main nav search Ui component
function toggleAriaAtts(siteSearch, status) {
    var ariaHiddens = siteSearch.querySelectorAll('[aria-hidden]');

    [].forEach.call(ariaHiddens, function(ariaEl) {
        if (ariaEl.closest('.site-search-trigger-buttons')) {
            // top level search btn is always visible
            ariaEl.setAttribute('aria-hidden', 'false');
        } else {
            ariaEl.setAttribute('aria-hidden', status);
        }
    });
}

function closeSearch(siteSearch) {
    var searchField = siteSearch.querySelector('.search-field'),
        siteSearchContent = siteSearch.querySelector('.site-search-content');

    // unfocus search-field before closing
    searchField.blur();
    toggleAriaAtts(siteSearch, 'true');
    siteSearch.classList.remove('expanded');

    if (siteSearchContent) {
        siteSearch.addEventListener('transitionend', (event) => {
            if (event.propertyName === 'opacity') {
                siteSearchContent.style.display = 'none';
            }
        }, { once: true });
    }
}

function openSearch(siteSearch) {
    var searchField = siteSearch.querySelector('.search-field'),
        siteSearchContent = siteSearch.querySelector('.site-search-content');

    if (siteSearchContent) {
        siteSearchContent.style.display = 'block';
    }
    
    siteSearch.classList.add('expanded');
    toggleAriaAtts(siteSearch, 'false');
    searchField.focus();
}

// if site search is expanded and user click away, close search
// status is either 'open' or 'close'
function toggleSiteSearchUi(status, event) {
    var siteSearch;
    if (status === 'open') {
        if (event) {
            siteSearch = event.target.classList.contains('.site-search') ? event.target : event.target.closest('.site-search');
            if (siteSearch) {
                openSearch(siteSearch);
            }
        }
    } else if (status === 'close') {
        siteSearch = document.querySelector('.site-search.expanded');
        if (siteSearch) {
            closeSearch(siteSearch);
        }
    }
}

function closeDesktopSearch() {
    var siteSearchExpanded = document.querySelector('.header-column-3 .site-search.expanded');

    if (siteSearchExpanded) {
        closeSearch(siteSearchExpanded);
    }
}

function closeMainNavSearchUi(e) {
    var siteSearchExpanded = document.querySelector('.site-search.expanded');
    
    if (siteSearchExpanded) {
        var targetInSearch = e.target.classList.contains('.expanded') || e.target.closest('.expanded');
        if (!targetInSearch) {
            closeSearch(siteSearchExpanded);
        }
    }
}

module.exports = {
    closeMainNavSearchUi: closeMainNavSearchUi,
    toggleSiteSearchUi: toggleSiteSearchUi,
    closeDesktopSearch: closeDesktopSearch
}