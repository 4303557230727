/**
 * phoneFormat
 * @param {*} inputValue - Value of html phone input
 * @returns a new string that is formatted for Phones (###) ###-####
 */
function phoneFormat(inputValue) {
    inputValue = inputValue.replace(/\D/g,'');
    var size = inputValue.length;

    if (size>0) {inputValue="("+inputValue}
    if (size>3) {inputValue=inputValue.slice(0,4)+") "+inputValue.slice(4,11)}
    if (size>6) {inputValue=inputValue.slice(0,9)+"-" +inputValue.slice(9)}

    return inputValue;
}

/**
 * inputMask.init()
 * Adds input masking to all inputs that are type=tel
 * As the user types, the corresponding values are wrapped like so: (###) ###-####
 */
function init() {
    var phoneInputs = document.querySelectorAll('input[type=tel]'),
        localeEl = document.querySelector('[data-current-locale]');
    
    if (localeEl) {
        var locale = localeEl.getAttribute('data-current-locale');

        if ( (locale.includes('US') || locale.includes('CA')) && phoneInputs.length > 0 ) {
            [].forEach.call(phoneInputs, function(phoneInput) {
                // if phoneInput has a value
                if (!(phoneInput.value === '')) {
                    var newVal = phoneFormat(phoneInput.value);
    
                    phoneInput.value = newVal; 
                }
    
                // attach event handler to input, blur events
                ['input', 'blur'].forEach(function(event) {
                    phoneInput.addEventListener(event, function(e) {
                        var val = e.target.value,
                            newVal = phoneFormat(val);
    
                        e.target.value = newVal; 
                    });
                });
            });
        }
    }
}

module.exports = {
    init: init
}
