'use strict';

const formValidation = require('base/components/formValidation');
const Hosted = require('org_citizen/components/video/hosted');
const VideoModal = require('core/components/video/videoModal');
const videojs = require('video.js/dist/video.min.js');

function displayMessage(isSuccess, message) {
    const status = (isSuccess) ? 'alert-success' : 'alert-danger';

    if ($('.stock-notification-message').length === 0) {
        $('body').append('<div class="toast-messages stock-notification-message"></div>');
    }

    $('.stock-notification-message').append(`<div class="alert text-center ${status}">${message}</div>`);

    setTimeout(() => $('.stock-notification-message').remove(), 3000);
}

function handleModalHtml(html) {
    $('#watchCaliberInfoModal').remove();
    $('body').append(html);
}

function handleModalVideo() {
    $('#videoModal').find('.modal-body').empty();

    const $hostedElement = $('body').find('video[data-video=hosted]');
    if ($hostedElement.length > 0) {
        window.videojs = videojs;
        const playerId = $hostedElement.attr('id');
        window.Players[playerId] = new Hosted(playerId);
        window.Players[playerId].setModal(VideoModal);
    }
}

function displayModal() {
    $('#watchCaliberInfoModal').modal('show');
}

function init() {
    $('#watchCalibersForm').on('submit', (e) => {
        e.preventDefault();

        const $form = $(e.currentTarget);
        const url = $form.attr('action');
        const caliberNumber = $form.find('input').val();

        $.ajax({
            url,
            data: {
                caliberNumber: caliberNumber
            },
            beforeSend: () => $.spinner().start(),
            complete: () => $.spinner().stop(),
            success: (data) => {
                if (data.hasFormError) {
                    formValidation($form, data);
                    return;
                }

                handleModalHtml(data);
                handleModalVideo();
                displayModal();
            },
            error: () => {
                const message = $form.data('general-error-message');
                displayMessage(false, message);
            },
        });
    });
}

module.exports = {
    init
}