'use strict';

var abSlider = require('../components/slider');
var productBase = require('../product/base');
// hold state of whether to run ajax and refresh cart.
var miniCartState = {
    update: false
};

/** Checks for the Zendesk widget buttons and updates zIndex to prevent overlay on minicart */
function checkZendeskButtonZindex() {
    var launchers = document.querySelectorAll('#launcher');

    if (launchers.length > 0) {
        [].forEach.call(launchers, function(launcher) {
            var sibs = $(launcher).siblings();

            launcher.style.zIndex = 100;

            sibs.each(function() {
                this.style.zIndex = 100;
            });
        })
    }
}

function calculatePopoverBottomOffsetAndShowMiniCart() {
    var $enhancedHeader = $('.fixed-header-enhanced'),
        $popOverBottom = $('.minicart .popover-bottom');

    $('body').addClass('modal-open minicart-open');
    // make popover block display to calculate height before transformation
    if ($enhancedHeader.length > 0) {
        $popOverBottom.css('top', '0');
    }

    // add class to start transition
    $('.minicart .popover').addClass('show');

    $('<div>', {
        class: 'modal-background',
        ariaHidden: 'false',
        style: 'display: block; top: 0px;'
    }).appendTo('body');
}

// hideProductOptionModal (if it exists)
function hideProductOptionModal() {
    var productOptionModal = document.querySelector('#productOptionModal');
    if (productOptionModal) {
        var closeBtn = productOptionModal.querySelector('[data-dismiss=modal]');
        if (closeBtn) {
            closeBtn.click();
        }
    }
}

function updateCheckoutOrderSummary(responseObject) {
    var url = $('.minicart').data('action-url');

    $.get(url, data => {
        var productSummary = $(data).find('.product-summary'),
            minicartFooter = $(data).find('.minicart-footer');

        if (productSummary) {
            $('#checkout-main .product-summary-block').empty().append(productSummary);
        }

        if (minicartFooter) {
            $('#checkout-main .order-total-summary').empty().append(minicartFooter);
        }
    });
}

function miniCartOpen(pliuuid) {
    if ($('.search:visible').length === 0) {
        return;
    }
    var url = $('.minicart').data('action-url');
    var count = parseInt($('.minicart .minicart-quantity').text(), 10);

    // append most recently added pliuuid as param on url
    if (pliuuid !== null) {
        if (url.indexOf('?') > -1) {
            url += '&pliuuid=' + pliuuid;
        } else {
            url += '?pliuuid=' + pliuuid;
        }
    }

    if (count !== 0) {
        if (!miniCartState.update && !$('.minicart .popover').is(':empty')) {
            $('.minicart').removeClass('show-success-message');
            calculatePopoverBottomOffsetAndShowMiniCart();

            return;
        }

        $.get(url, data => {
            $('.minicart .popover').empty().append(data);
            // set miniupdate to false as cart has been newly updated
            if (url.indexOf('pliuuid') !== -1) {
                $('.minicart').addClass('show-success-message');
            }
            
            if ($('.minicart-cross-sells').length > 0) {
                abSlider.initializeSliders($('.minicart-cross-sells'));
            }
            
            calculatePopoverBottomOffsetAndShowMiniCart();

            checkZendeskButtonZindex();
            
            miniCartState.update = false;

            $('body').trigger('minicart:loaded', $('.minicart .popover'));
        });
    }
}

function miniCartClose() {
    if ($('body').hasClass('minicart-open')) {
        var minicartEl = document.querySelector('.minicart .popover');
        var duration = parseFloat(getComputedStyle(minicartEl)['transitionDuration']) * 1000;
        var $popOverBottom = $('.minicart .popover-bottom');
        
        $popOverBottom.removeClass('show');

        $('.modal-background').remove();
        
        setTimeout(function() {
            $('body').removeClass('modal-open minicart-open').trigger('minicart:mouseleave_focusout');
        }, duration);
    }
}


function init() {
    $('.minicart').on('count:update', (event, count) => {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });

            // update accutron menu minicart link
            $('.nav-item-minicart-burger .minicart-quantity').text(count.quantityTotal);
        }
    });

    // THIS IS MINICART SHOW CODE ON CLICK
    $('.minicart').on('click', (e) => {
        e.preventDefault();
        var internalMiniCartLink = $(e.target).closest('[href]'),
            isQuickviewLink = e.target.closest('.quickview');
        
        // if an internal minicart link is clicked, forward window location to href
        if (internalMiniCartLink.length > 0 && !internalMiniCartLink.hasClass('minicart-link') && !isQuickviewLink) {
            window.location = internalMiniCartLink.attr('href');
            return;
        }

        // otherwise, open minicart
        if ( !$('body').hasClass('minicart-open') ) {
            miniCartOpen(null);
        }
    });

    // close minicart if minicart is open and click is not on minicart
    $('body').on('click', event => {
        // if click event target not in minicart or product remove modal, close minicart
        if ($('.minicart').has(event.target).length <= 0 && !$('#removeProductModal').has(event.target).length > 0 && !$('#quickViewModal').has(event.target).length > 0 ) {
            miniCartClose();
        } else {
            // if event target is .minicart-close (inside of minicart), close modal
            if ($(event.target).hasClass('minicart-close')) {
                miniCartClose();
            }

            if ($(event.target).hasClass('continue-shopping')) {
                miniCartClose();
            }
        }
    });

    $('body').on('change', '.minicart .quantity', event => {
        if ($(event.target).parents('.bonus-product-line-item').length && $('.cart-page').length) {
            location.reload();
        }
    });

    $('body').on('product:afterAddToCart cart:update', (e, responseObject) => {
        var pliuuid = 'pliUUID' in responseObject && responseObject.pliUUID !== '' ? responseObject.pliUUID : null;
        // check for an error: 
        if (!responseObject.error) {
            miniCartState.update = true;

            if ('isCheckout' in responseObject && responseObject.isCheckout) {
                updateCheckoutOrderSummary(responseObject);
            } else {
                // hide product option modal
                hideProductOptionModal();
                // open miniCart
                miniCartOpen(pliuuid);
            }       
        }
    });

    /**
     * Cross Sell Add to Cart click event handler
     */
    $('body').on('click', '.cross-sells-add-to-cart', function (e) {
        e.preventDefault();

        $('body').trigger('product:beforeAddToCart', this);

        var quickAddToCartUrl = $(e.target).data('url');

        var form = {
            pid: $(e.target).data('pid'),
            quantity: 1,
            options: []
        };

        $(this).trigger('updateAddToCartFormData', form);

        if (quickAddToCartUrl) {
            $.spinner().start();
            $.ajax({
                url: quickAddToCartUrl,
                method: 'POST',
                data: form,
                success: function(data) {
                    productBase.methods.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    productBase.methods.miniCartReportingUrl(data.reportingURL);
                    $.spinner().stop();
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });    
};

module.exports = {
    init: init()
};
