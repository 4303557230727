'use strict';

const debounce = require('lodash/debounce');
const viewportUtils = require('org_citizen/utilities/viewport');
const stringUtils = require('org_citizen/utilities/string');

function getVisibleText($textContainer, maxHeight) {
    while ($textContainer.height() > maxHeight) {
        $textContainer.html((index, text) => stringUtils.removeLastWord(text));
    }

    $textContainer.html(stringUtils.removeLastWord($textContainer.html()));

    return $textContainer.html();
}

function createShowMoreTextComponent() {
    const isMobile = !viewportUtils.mediaBreakpointUp('md');
    const maxHeight = (isMobile) ? 60 : 44; // these values are required to match the heights set witin _showMoreText.scss
    const $showMoreText = $('.show-more-text');
    const $textContentContainer = $showMoreText.find('.show-more-text-content');
    const $textActionsContainer = $showMoreText.find('.show-more-text-actions');

    if (!$textContentContainer.length) {
        return;
    }

    let text = '';

    if (!$textContentContainer.data('initial-text')) {
        text = $textContentContainer.html();
        $textContentContainer.data('initial-text', text)
    } else {
        $textContentContainer.empty();
        text = $textContentContainer.data('initial-text')
        $textContentContainer.html(text);
    }

    if ($textContentContainer.height() <= maxHeight) {
        $textActionsContainer.addClass('d-none');
        return;
    } else {
        $textActionsContainer.removeClass('d-none');
    }

    const visibleText = getVisibleText($textContentContainer, maxHeight);
    const $visibleText = $('<span class="visible-text"></span>');
    $visibleText.html(visibleText);

    const $moreText = $('<span class="more-text"></span>');
    const moreText = text.substring(visibleText.length, text.length);
    $moreText.html(moreText)

    $textContentContainer.empty();

    $textContentContainer.append($visibleText);
    $textContentContainer.append($('<span class="ellipsis-text">...</span>'));
    $textContentContainer.append($moreText);
}

function init() {
    createShowMoreTextComponent();
}

function eventHandlers() {
    $(window).on('resize', debounce(() => createShowMoreTextComponent(), 50));
}

module.exports = {
    init: init,
    eventHandlers: eventHandlers
}