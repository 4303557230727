'use strict';

var recaptcha = require('core/components/recaptcha');
const formValidation = require('base/components/formValidation');

function displayMessage(isSuccess, message, headline, $modal) {
    const status = (isSuccess) ? '' : 'alert-danger',
        image = document.querySelector('.primary-images-desktop .slider .slide > img'),
        $modalContent = $modal.find('.modal-content'),
        closeModal = document.querySelector('[data-dismiss="modal"]');

    let imageEl = image ? image.cloneNode(true) : false;

    // update modal body with message  
    $modal.find('#stockNotificationForm').addClass('d-none');
    closeModal.classList.add('mx-auto', 'px-5');
    
    $modalContent.append(`<h3 class="text-center text-capitalize font-size--26">${headline}</h3>`);
    $modalContent.append(`<p class="text-center ${status}">${message}</p>`);

    if (isSuccess) {
        $modalContent.addClass('success');
    }

    if (imageEl) {
        imageEl.classList.add('notify-modal-image');
        $modalContent.append(imageEl);
    }
    
    $modalContent.append(closeModal);
}

function handleLoggedInCustomer() {
    const loggedInBtn = document.querySelector('[data-customer-email]');
    
    if (loggedInBtn) {
        const notificationEmail = document.querySelector('#stockNotificationForm #email');

        notificationEmail.value = loggedInBtn.dataset.customerEmail;

        $('.btn-notify').on('click', function(e) {
            if (!document.querySelector('#stockNotificationModal .success')) {
                $('#stockNotificationForm').trigger('submit');
            }
        });
    }
}

function handleFormSubmit() {
    $('#stockNotificationForm').on('submit', (e) => {
        e.preventDefault();
        
        const $form = $(e.currentTarget);
        const $modal = $form.closest('.modal');
        const $modalContent = $form.closest('.modal-content');
        const data = $form.serialize();
        const url = $form.attr('action');

        $modalContent.spinner().start();

        recaptcha.check(e, {
            url,
            data,
            type: 'POST',
            success: (data) => {
                if (!data.success) {
                    formValidation($form, data);

                    if (data.message) {
                        displayMessage(data.success, data.message, data.headline, $modal);
                    }

                    return;
                } else {
                    displayMessage(data.success, data.message, data.headline, $modal);
                }
            },
            error: () => {
                const message = $form.data('general-error-message');
                displayMessage(false, message, false, $modal);
            },
            complete: () => {
                $modalContent.spinner().stop();
            }
        });
    });
}

module.exports = {
    handleFormSubmit: handleFormSubmit,
    handleLoggedInCustomer: handleLoggedInCustomer
}