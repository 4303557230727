'use strict';

var throttle = require('lodash/throttle');
var debounce = require('lodash/debounce');

function maxHeight(max, elem) {
    var h = elem.getBoundingClientRect().height;
    return max > h ? max : h;
}

/** function to fix viewport height issues with iOS and browser chrome */
function setDocumentHeight() {
    var doc = document.documentElement;
    doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
}

function setBurgerToggleTop() {
    var burgerToggle = document.querySelector('#top-header > .burger-menu-toggler');
    var headerNav = document.querySelector('.header-nav');
    var headerNavRect = headerNav.getBoundingClientRect();
    if (burgerToggle && headerNavRect.top > 0) {
        var tickerHeight = $('#global-ticker .ticker').toArray().reduce(maxHeight, 0);
        var burgerNavOffsetFromHeaderNav = ((headerNavRect.height - burgerToggle.getBoundingClientRect().height) / 2);
        var burgerToggleOffset = burgerNavOffsetFromHeaderNav + tickerHeight;
        var accessibility = document.querySelector('.accessibility');

        if (window.innerWidth < 1024 && accessibility) {
            burgerToggleOffset += accessibility.getBoundingClientRect().height;
        }

        $(burgerToggle).css('top', parseInt(burgerToggleOffset, 10) + 'px');
    }
}

var updateHeader = status => {
    var $headerNav = $('.header-nav');
    var $globalTicker = $('#global-ticker');
    var headerNavHeight = $headerNav.outerHeight();
    var $page = $('.page');
    var $mainMenu = $('.main-menu');
    var $topHeader = $('#top-header');
    var $burgerToggle = $('#top-header .burger-menu-toggler');
    var body = document.querySelector('body');

    // if header has transparent-header class and global ticker is activated
    // add height of glbal ticker to headerNavHeight
    if ($globalTicker.length > 0 && $topHeader.hasClass('transparent-header')) {
        headerNavHeight = headerNavHeight + $globalTicker.outerHeight();
    }

    if (!$mainMenu.hasClass('in')) {
        if (status === 'fixed') {
            if ($topHeader.hasClass('fixed-header')) {
                $headerNav.addClass('fixed');
                $globalTicker.addClass('fixed');
                if (!($headerNav.closest('.transparent-header').length)) {
                    $page.css('margin-top', headerNavHeight + 'px');
                }
                $headerNav.removeClass('transparent-nav');
            }

            if ($burgerToggle.length > 0 && !$burgerToggle.hasClass('burger-menu-toggler-fixed')) {
                if ($headerNav.get(0).getBoundingClientRect().top < 0) {
                    $burgerToggle.css('top', '');
                    $burgerToggle.addClass('burger-menu-toggler-fixed');
                }
            }
        } else {
            var isMobileOpen = body.classList.contains('mobile-menu-in');
            var isRefinementBarOpen = body.classList.contains('js-body-is-locked');

            if ($topHeader.hasClass('fixed-header') && !isRefinementBarOpen) {
                $headerNav.removeClass('fixed');
                $globalTicker.removeClass('fixed');
                $page.css('margin-top', '');

                if (!isMobileOpen){
                    $headerNav.addClass('transparent-nav');
                }
            }

            if ($burgerToggle.length > 0 && $burgerToggle.hasClass('burger-menu-toggler-fixed') && !isMobileOpen) {
                $burgerToggle.removeClass('burger-menu-toggler-fixed');
                setBurgerToggleTop();
            }
        }
    }
}

/**
 * If global-ticker is not preset, add no-global-ticker class
 * to body so full screen hero elements can go all the way
 */
function checkForGlobalTicker() {
    var globalTicker = document.querySelector('#global-ticker');

    if (!globalTicker) {
        $('body').addClass('no-global-ticker');
    }
}

module.exports = function() {
    // Fix/unfix header to top of screen, dependent on class added by custom preference
    // Note: This is intentionally not using utilities/scroll.js so that it can fix immediately vs. being debounced. Should be replaced with CSS position:sticky once IE11 support is dropped.
    var hasFixedHeader = $('#top-header').hasClass('fixed-header');
    var hasBurger = $('#top-header .burger-menu-toggler').length > 0;
    var backToTop = document.querySelector('.back-to-top');
    var throttleTime = 70;

    if (hasBurger) {
        setBurgerToggleTop();

        $(window).resize(debounce(function() {
            setBurgerToggleTop()
        }, 200));
    }

    $(window).scroll(throttle(function(event) {
        var scrollTop = $(event.target).scrollTop();
        if (hasFixedHeader || hasBurger) {
            var $headerBanner = $('.header-banner');
            var headerBannerHeight = $headerBanner.length ? $headerBanner.outerHeight() : 0;
            var $preNavHeader = $('.pre-nav-header');
            var preNavHeaderHeight = $preNavHeader.length ? $preNavHeader.outerHeight() : 0;
            var $bubbleSearchExpanded = $('.bubble-search.expanded');

            if (scrollTop > headerBannerHeight + preNavHeaderHeight) {
                updateHeader('fixed');
            } else {
                var $searchField = $bubbleSearchExpanded.find('.focus-visible');
                $bubbleSearchExpanded.removeClass('expanded');                
                $searchField.removeClass('focus-visible');
                $searchField.blur();
                updateHeader('unfixed');
            }
        }

        if (backToTop) {
            var threshold = 160;
            if (scrollTop > threshold) {
                backToTop.classList.add('active');
            } else {
                backToTop.classList.remove('active');
            }
        }

    }, throttleTime, {'leading': true} ));

    var isMobile = window.isMobile();
    if ($('#top-header').hasClass('transparent-header') && !isMobile) {
        $('.header-nav').hover(
            function() {
                $(this).removeClass('transparent-nav');
            }, function() {
                $(this).addClass('transparent-nav');
            }
        );
    }

    // Check for presence of content assets in MegaMenu
    var $megamenuDropdown = $('.megamenu > .dropdown-menu');

    $megamenuDropdown.each((index, element) => {
        var $megamenu = $(element);
        if ($megamenu.find('.megamenu-asset-1').children().length) {
            $megamenu.addClass('has-asset-1');
        }
        if ($megamenu.find('.megamenu-asset-2').children().length) {
            $megamenu.addClass('has-asset-2');
        }
    });

    // Show/hide content assets on mobile based on category level
    if (isMobile) {
        var $thirdMenuBackBtn = $('.megamenu .menu-subcategories > .dropdown-item'),
            $thirdSubLink = $('.megamenu .menu-subcategories > .dropdown-item > .dropdown-link');

        $thirdSubLink.on('click', function () {
            $('.megamenu.show .menu-subcategories').parent('li').addClass('thirdCatOpen');
        });
        $thirdMenuBackBtn.on('click', function () {
            $('.megamenu.show .menu-subcategories').parent('li').removeClass('thirdCatOpen');
        });
    }

    $('.main-menu .navbar-nav, .navbar-nav-secondary').on('mouseenter mouseleave', function () {
        $(this).toggleClass('nav-item-hover');
    });

    $('form[name=simpleSearch]').on('submit', event => {
        var $form = $(event.target);
        var $input = $('input[name=q]',$form);
        //keep search from submitting if query is empty
        if ($input.val() == "") {
            $input.focus();
            event.preventDefault();
        }
    });

    /** set doc height css var and add resize listener */
    setDocumentHeight();
    /** check for global-ticker, if not there add .no-global-ticker to body */
    checkForGlobalTicker();

    $(window).on('resize', debounce(function() {
        setDocumentHeight();
    }, 200));

    if (!isMobile) {
        $('header .site-search').on('mouseenter', event => {
            var $siteSearch = $(event.target).closest('.site-search');
            $siteSearch.addClass('expanded');
        });

        $('header .site-search').on('mouseleave', event => {
            var $siteSearch = $(event.target).closest('.site-search');
            var $input = $siteSearch.find('input');
            // Only collapse if the user isn't typing and hasn't entered text

            if (!$input.hasClass('focus-visible')) {
                if (!$input.is(':focus') && !$input.val()) {
                    $siteSearch.removeClass('expanded');
                }
            }
        });

        $('body').on('click', event => {
            var target = event.target,
                isBubble = ($(target).hasClass('bubble-search') || $(target).closest('.bubble-search').length > 0);
            if (!isBubble) {
                var $siteSearch = $('#top-header .bubble-search');
                $siteSearch.removeClass('expanded');
            }
        });

    } else {
        $('header .site-search').on('click', event => {
            var $siteSearch = $(event.target).closest('.site-search');

            // state is closed - open UI
            if (!$siteSearch.hasClass('expanded')) {
                $siteSearch.addClass('expanded');
            } else {    
                // state is open - close UI
                var $input = $siteSearch.find('input');
                // Only collapse if the user isn't typing and hasn't entered text
                if (!$input.is(':focus') && !$input.val()) {
                    $siteSearch.addClass('bubble-search-slide-up');

                    window.setTimeout(function() {
                        $siteSearch.removeClass('expanded');
                        $siteSearch.removeClass('bubble-search-slide-up');
                    }, 200);
                }
            }
        });

        $('body').on('click', event => {
            var target = event.target,
                isBubble = ($(target).hasClass('bubble-search') || $(target).closest('.bubble-search').length > 0);
            if (!isBubble) {
                var $siteSearch = $('#top-header .bubble-search');
                $siteSearch.addClass('bubble-search-slide-up');

                window.setTimeout(function() {
                    $siteSearch.removeClass('expanded');
                    $siteSearch.removeClass('bubble-search-slide-up');
                }, 200);
            }
        });
    }

    $('.burger-menu .search-field').on('blur', function() {
        var $siteSearch = $(event.target).closest('.site-search');
        $siteSearch.removeClass('expanded');
    });

    $('form[name=simpleSearch]').on('submit', event => {
        var $form = $(event.target);
        var $input = $('input[name=q]',$form);
        //keep search from submitting if query is empty
        if ($input.val() == "") {
            $input.focus();
            event.preventDefault();
        }
    });
};
