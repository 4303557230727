'use strict';

function closableColumnInit() {
    var $columnCloseEls = $('.column-close');

    $columnCloseEls.each(function() {
        var $container = $(this).closest('.closable-column-content');
        var $pdWrapper = $(this).closest('.experience-component');
        var $closableContent = $container.find('.column-component-closable');
        var $element = $(this);
        
        // update relative / aboslute classes on element and parent to ensure
        // no layout thrashing when collapsible content initially renders
        $pdWrapper.addClass('position-relative');
        $container.addClass('position-absolute');
        // show container now component elements have been updated
        $container.removeClass('closable-column-content-not-initialized');

        $element.on('click', function(event) {
            $closableContent.removeClass('show');

            $closableContent.on('transitionend webkitTransitionEnd oTransitionEnd', function () {
                $container.addClass('d-none');
            });
        });
    });
}

module.exports = function () {
    // closable Column Button
    closableColumnInit();
};