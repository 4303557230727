/**
 * toggleElementHeight
 * @param {*} el - element height that will animate
 */
function toggleElementHeight(el) {
    var container = el.closest('[data-toggle-height-block]'),
        toggleBody;

    if (container) {
        toggleBody = container.querySelector('[data-toggle-height-body]');

        if (toggleBody) {
            if (container.classList.contains('toggle-height-active')) {   
                container.classList.remove('toggle-height-active');
            } else {
                container.classList.add('toggle-height-active');
            }
        }
    }
}

/**
 * toggleElementHeight.init()
 * hide/show order summary on checkout in mobile
 */
function init() {
    var toggles = document.querySelectorAll('[data-toggle-height]');

    if (toggles.length > 0) {
        [].forEach.call(toggles, function(toggle) {
            toggle.addEventListener('click', function(e) {
                var target = e.target;
                toggleElementHeight(target);
            })
        });
    }
}

module.exports = {
    init: init
}
