'use strict';
/**
 * This is client side JS to accompany the new 'on hover' option for text placement
 * for the imageAndText PD Component. If 'on hover' is selected in BM, then the text content
 * is shown on hover w/ the corresponding animation selected in the BM PD component UI, as 
 * opposed to the default behavior to hide/show component with animation on scroll, which is 
 * over ridden by the 'on hover' selection.
 * 
 * @param {*} component - imageAndText PD component html
 */
function showComponentOnHover(component) {
    var animationEl = component.querySelector('[data-animation-on-hover]');
    
    if (animationEl) {
        var animation = animationEl.dataset.animationOnHover;
        
        if (animation) {
            component.addEventListener('mouseover', function(e) {
                animationEl.classList.remove('animation-initial-state');
                animationEl.classList.add(animation);
            });

            component.addEventListener('mouseout', function(e) {
                animationEl.classList.remove(animation);
                animationEl.classList.add('animation-initial-state');
            });
        }
    }
}

function init() {
    var showOnHovers = document.querySelectorAll('.show-on-hover');

    if (showOnHovers.length > 0) {
        [].forEach.call(showOnHovers, showComponentOnHover);
    }
}

module.exports = init();