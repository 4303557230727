'use strict';

var base = require('integrations/product/base');
var scrollToElement = require('./scrollToElement');
var flagshipModal = require('./flagshipModal');

module.exports = Object.assign(base, {
    optionSelect: () => {
        $('body').on('change', '.product-options .options-select', (e) => {
            e.preventDefault();
            const $currentOptionsSelect = $(e.currentTarget);
            const $selectedOption = $currentOptionsSelect.find(':selected');
            const $optionSelects = $('.product-options .options-select').not($currentOptionsSelect);
            const $optionsToSelect = $optionSelects.find(`option[data-value-id="${$selectedOption.data('value-id')}"`);

            $optionsToSelect.prop('selected', true);
        });
    },
    selectAttribute: () => {
        $(document).on('change', 'select[class*="select-"]', function (e) {
            e.preventDefault();

            var $productContainer = $(this).closest('.set-item');
            if (!$productContainer.length) {
                $productContainer = $(this).closest('.product-detail');
            }
            base.methods.attributeSelect(e.currentTarget.value, $productContainer);
        });
    },
    handleApplePayPrepareBasketEvent: () => {
        $('body').on('applePayPrepareBasket', () => {
            const $bandSizeOptionContainer = $('[data-option-id="bandSize"]');
            const $applePayBtn = $('.dw-apple-pay-button');

            if ($bandSizeOptionContainer.length && $applePayBtn.length) {
                const productId = $applePayBtn.attr('sku');
                const optionId = $bandSizeOptionContainer.find('option:selected').data('value-id');

                $.ajax({
                    url: window.CachedData.urls.applePayUpdateBasketUrl,
                    type: 'POST',
                    dataType: 'json',
                    data: {
                        productId: productId,
                        optionId: optionId
                    }
                });
            }
        });
    },
    addToCart: function () {
        var scope = this;

        // if qty stepper input has focus, we need to blur it so product data can update before button click
        $(document).on('mouseenter', 'button.add-to-cart, button.add-to-cart-global', function (event) {
            var $button = $(event.target);
            var $container = $button.closest('.product-detail');
            if (!$container.length) {
                $container = $button.closest('.quick-view-dialog');
            }
            var $qtyStepperInput = $container.find('.quantity-stepper input');

            if ($qtyStepperInput.length && $qtyStepperInput.is(':focus')) {
                $qtyStepperInput.blur();
            }
        });

        $(document).on('click', 'button.add-to-cart, button.add-to-cart-global', function (event) {
            var addToCartUrl;
            var pid;
            var pidsObj;
            var setPids;
            var quantity;

            // validate bandSize
            var ctaContainer = event.target.closest('.add-to-cart-container');

            if (ctaContainer) {
                var bandSize = ctaContainer.querySelector('[data-option-id=bandSize]');
                if (bandSize) {
                    var option = bandSize.querySelector('.options-select');
                    if (option.value === '') {
                        option.classList.add('is-invalid');
                        $.spinner().stop();
                        return false;
                    }
                }
            }
            // end validate bandSize

            $('body').trigger('product:beforeAddToCart', this);

            if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
                setPids = [];

                var $products = $(this).closest('.product-detail').find('.product-set-item-detail');
                if (!$products.length) {
                    if ($(this).closest('.quick-view-dialog').length) {
                        $products = $(this).closest('.quick-view-dialog').find('.product-set-item-detail');
                    } else {
                        $products = $('.product-detail');  // pagedesigner component 'Add all to cart btn' won't have .product-set-item-detail classes
                    }
                }

                $products.each(function () {
                    if (!$(this).hasClass('product-set-detail')) {
                        setPids.push({
                            pid: $(this).find('.product-id').text(),
                            qty: $(this).find('.quantity-select').val(),
                            options: scope.methods.getOptions($(this))
                        });
                    }
                });
                pidsObj = JSON.stringify(setPids);  
            }

            pid = scope.methods.getPidValue($(this));

            quantity = $(this).hasClass('single-variant-quick-add-to-cart') ? 1 : scope.methods.getQuantitySelected($(this));

            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                if ($(this).hasClass('single-variant-quick-add-to-cart')) {
                    addToCartUrl = $(this).data('url');
                } else {
                    $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
                    var $productModalbody = $(this).closest('.modal-content');
                    addToCartUrl = scope.methods.getAddToCartUrl($productModalbody);
                }
            } else {
                addToCartUrl = scope.methods.getAddToCartUrl($productContainer);
            }

            var form = {
                pid: pid,
                pidsObj: pidsObj,
                childProducts: scope.methods.getChildProducts(),
                quantity: quantity,
                options: scope.methods.getOptions($productContainer)
            };

            $(this).trigger('updateAddToCartFormData', form);
            if (addToCartUrl) {
                $.ajax({
                    url: addToCartUrl,
                    method: 'POST',
                    data: form,
                    success: function (data) {
                        scope.methods.handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                        $.spinner().stop();
                        scope.methods.miniCartReportingUrl(data.reportingURL);
                    },
                    error: function () {
                        $.spinner().stop();
                    }
                });
            }
        });
    },
    scrollToElement: scrollToElement.init(),
    flagshipModalInit: flagshipModal.init()
});
