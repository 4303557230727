'use strict';

var debounce = require('lodash/debounce');

var initialized = false;

function buildAutoAspectRatio(videoContainer) {
    var region = $(videoContainer).closest('.experience-region');
    var video = videoContainer.querySelector('video');
    var videoOrientation = video.videoHeight > video.videoWidth ? 'portrait' : 'landscape';
    var videoAspectRatio = videoOrientation === 'portrait' ? (video.videoHeight / video.videoWidth) : (video.videoWidth / video.videoHeight);
    var regionOrientation = region[0].clientHeight > region[0].clientWidth ? 'portrait' : 'landscape';
    var regionAspectRatio = regionOrientation === 'portrait' ? (region[0].clientHeight / region[0].clientWidth) : (region[0].clientWidth / region[0].clientHeight);

    // reset css vars for recalc
    videoContainer.style.setProperty('--aspectRatioWidth', '');
    videoContainer.style.setProperty('--aspectRatioHeight', '');
    videoContainer.style.setProperty('--aspectRatioCover', '');
    
    if (videoContainer.classList.contains('aspect-ratio-prioritize-width')) {
        // prioritize width
        video.style.height = 'auto';
        // transpose video dimensions to region
        var rHeight = region[0].getBoundingClientRect().height;
        var vHeight = region[0].getBoundingClientRect().width * videoAspectRatio;
        // adjust video if dimensions are bigger than regions dimensions
        if (vHeight > rHeight) {
            video.style.top = '50%';
            video.style.transform = 'translate(0, -50%)';
        }
        // set dom css property
        videoContainer.style.setProperty('--aspectRatioWidth', (regionAspectRatio * 100) + '%' );
        
    } else if (videoContainer.classList.contains('aspect-ratio-prioritize-height')) {
        // prioritize height
        video.style.width = 'auto';
        // transpose video dimensions to region
        var rWidth = region[0].getBoundingClientRect().width;
        var vWidth = region[0].getBoundingClientRect().width * regionAspectRatio;
        // adjust video if dimensions are bigger than regions dimensions
        if (vWidth > rWidth) {
            video.style.left = '50%';
            video.style.transform = 'translate(-50%, 0)';
        }
        // set dom css property
        videoContainer.style.setProperty('--aspectRatioHeight', (regionAspectRatio * 100) + '%' );
        
    } else if (videoContainer.classList.contains('aspect-ratio-cover')) {
        // in cover mode we are only concenerd with videoSpecs
        if (videoOrientation === 'portrait') {
            video.style.height = 'auto';    
            video.style.top = '50%';
            video.style.transform = 'translate(0, -50%)';
        } else {
            video.style.width = 'auto';
            video.style.left = '50%';
            video.style.transform = 'translate(-50%, 0)';
        }

        // set dom css property
        videoContainer.style.setProperty('--aspectRatioCover', (regionAspectRatio * 100) + '%' );
    }
}   

function buildAllVideosOnResize(videoContainers) {
    [].forEach.call(videoContainers, function(videoContainer) {
        var video = videoContainer.querySelector('video');
        
        if ( video && video.readyState === 4 ) {
            buildAutoAspectRatio(videoContainer)
        }
    });
}

function initAutoAspectRatios() {
    if (!initialized) {
        var videoContainers = document.querySelectorAll('.aspect-ratio-prioritize-width, .aspect-ratio-prioritize-height, .aspect-ratio-cover');

        [].forEach.call(videoContainers, function(videoContainer) {
            var video = videoContainer.querySelector('video');
            if (video) {
                if ( video.readyState === 4 ) {
                    buildAutoAspectRatio(videoContainer)
                } else {
                    video.addEventListener( "loadedmetadata", function (e) {
                        buildAutoAspectRatio(videoContainer);                    
                    }, false );
                }
            } else {
                // if there is no video, that means iframe is rendering. We must remove the prioritize and cover
                // classes as they will have no effect. 
                videoContainer.classList.remove('aspect-ratio-prioritize-width', 'aspect-ratio-prioritize-height', 'aspect-ratio-cover');
                // add default class to ensure proper padding
                videoContainer.classList.add('aspect-ratio-16-9');
            }
        });

        window.addEventListener('resize', debounce(function() {
            buildAllVideosOnResize(videoContainers);
        }, 250));

        initialized = true;
    }
}

function setupPdpCarouselVideoModal(selector) { 
    var $pdpCarouselVideoModal = $(selector),
        modalVideo = $pdpCarouselVideoModal.find('video'),
        modalTrigger = document.querySelector('[data-target="' + selector + '"]'),
        triggerVideoContainer = modalTrigger ? modalTrigger.closest('.video-container') : null,
        triggerVideo = triggerVideoContainer ? triggerVideoContainer.querySelector('[data-video]') : null,
        triggerVideoPlayer = triggerVideo ? window.Players[triggerVideo.id] : null;

    if ($pdpCarouselVideoModal && modalVideo.length > 0) {
        $pdpCarouselVideoModal.on('shown.bs.modal', (e) => {    
            if (triggerVideoPlayer) {
                modalVideo[0].currentTime = triggerVideoPlayer.cache.time;
            }
            modalVideo[0].play();
        });

        $pdpCarouselVideoModal.on('hidden.bs.modal', (e) => {    
            if (triggerVideoPlayer) {
                triggerVideoPlayer.domElements.$player[0].currentTime = modalVideo[0].currentTime;
            }
            modalVideo[0].pause();
        });
    }
}

function setupProductMarketingVideoModals() {
    var pdpCarouselModals = ['#productMarketingVideoModal'];

    pdpCarouselModals.forEach(function(modalId) { 
        setupPdpCarouselVideoModal(modalId);
    });
}

module.exports = {
    initAutoAspectRatios: initAutoAspectRatios,
    setupProductMarketingVideoModals: setupProductMarketingVideoModals
};