'use strict';

const SiteConstants = require('constants/SiteConstants');

function mediaBreakpointUp(size) {
    const width = SiteConstants.BreakpointSizes[size];
    const mediaQuery = window.matchMedia(`(min-width: ${width}px)`);

    return mediaQuery.matches;
}

module.exports = {
    mediaBreakpointUp: mediaBreakpointUp
};
