'use strict';

var debounce = require('lodash/debounce');

// Polyfill for creating CustomEvents on IE11

// code pulled from:
// https://github.com/d4tocchini/customevent-polyfill
// https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent#Polyfill

(function () {
    if (typeof window === 'undefined') {
        return;
    }

    try {
        var ce = new window.CustomEvent('test', { cancelable: true });
        ce.preventDefault();
        if (ce.defaultPrevented !== true) {
            // IE has problems with .preventDefault() on custom events
            // http://stackoverflow.com/questions/23349191
            throw new Error('Could not prevent default');
        }
    } catch (e) {
        var CustomEvent = function (event, params) {
            var evt, origPrevent;
            params = params || {};
            params.bubbles = !!params.bubbles;
            params.cancelable = !!params.cancelable;

            evt = document.createEvent('CustomEvent');
            evt.initCustomEvent(
                event,
                params.bubbles,
                params.cancelable,
                params.detail
            );
            origPrevent = evt.preventDefault;
            evt.preventDefault = function () {
                origPrevent.call(this);
                try {
                    Object.defineProperty(this, 'defaultPrevented', {
                        get: function () {
                            return true;
                        }
                    });
                } catch (e) {
                    this.defaultPrevented = true;
                }
            };
            return evt;
        };

        CustomEvent.prototype = window.Event.prototype;
        window.CustomEvent = CustomEvent; // expose definition to window
    }
})();

class ScrollDirection {
    constructor() {
        this.$html = $('html'); // Element to add CSS classnames
        this.window = window; // Element to listen to scroll events
        this.direction = null; // Current scroll direction
        this.headerHeight = null; // Track header's height
        this.headerNavHeight = null; // Track sticky portion of header's height
        this.headerNavOffsetTop = 0; // Track header's distance from top of page
        this.isScrolling = false; // Page is scrolling
        this.last = 0; // Last scroll position
        this.stickyHeader = false; // Show CSS header
        this.timeout = null; // Timeout event for scroll handler
        this.threshold = 10; // How far to allow scroll before triggering scroll events

        this.init(); // Initialize scroll handlers
    }

    init() {
        // Check if we can use Passive Event Listening
        let passiveIfSupported = false;
        try {
            window.addEventListener('test', null,
                Object.defineProperty({}, 'passive', {
                    get: function () {
                        passiveIfSupported = {
                            passive: true
                        };
                    }
                })
            );
        } catch (err) { }

        // Add Event Listeners
        this.listener = this.detectDirection.bind(this);
        this.resize = this.detectResize.bind(this);

        this.window.addEventListener('scroll', this.listener, passiveIfSupported);
        this.window.addEventListener('resize', debounce(this.resize, 200), passiveIfSupported);
        // Do initial Size Detection
        this.detectResize();
    }

    detectDirection() {
        // Get Scroll Position
        const scrolled = this.window.scrollY || this.window.scrollTop || this.window.pageYOffset;

        // Check if New Scroll Position Breaks Threshold
        if (Math.abs(scrolled - this.last) >= this.threshold) {
            const currentDirection = (scrolled > this.last) ? 'down' : 'up';

            // Check if Scrolling
            if (scrolled !== this.last && !this.isScrolling) {
                this.onScrollStart();
            }

            // Check if Header exists and if we should trigger Sticky Header
            if (this.headerHeight) {
                if (scrolled > this.headerHeight && !this.stickyHeader && this.direction === 'up') {
                
                    this.stickyHeader = true;
                    this.$html.addClass('sticky-header');
                    // Fire Custom Event `stickyHeaderChange`
                    this.window.dispatchEvent(new CustomEvent('stickyHeaderChange', {
                        detail: {
                            direction: this.direction,
                            last: this.last,
                            stickyHeader: this.stickyHeader
                        }
                    }));
                    // add conditional to check if html has 'lock-scroll' if so, dont fire a sticyChangeEvent as a modal, menu, or fixed popup is displayed
                } else if (scrolled <= 10 && this.stickyHeader && !$('html').hasClass('lock-scroll')) {
                    
                    this.stickyHeader = false;
                    this.$html.removeClass('sticky-header');
                    // Fire Custom Event `stickyHeaderChange`
                    this.window.dispatchEvent(new CustomEvent('stickyHeaderChange', {
                        detail: {
                            direction: this.direction,
                            last: this.last,
                            stickyHeader: this.stickyHeader
                        }
                    }));
                }
            }

            // Detect Direction Change
            if (this.direction !== currentDirection) {
                // add conditional to check if html has 'burger-menu-closing' if so, dont fire a sticyChangeEvent as a menu is animating out
                if (!$('html').hasClass('lock-scroll') && !$('body').hasClass('burger-menu-closing')) {
                    this.onDirectionChange(currentDirection);
                }
            }

            // Update Last Scroll Position
            this.last = scrolled;

            // Fire Custom Event `scrollUpdate`
            this.window.dispatchEvent(new CustomEvent('scrollUpdate', {
                detail: {
                    direction: this.direction,
                    last: this.last,
                    stickyHeader: this.stickyHeader
                }
            }));
        }

        // Clear Last Timeout before Recreating it
        if (this.timeout) {
            clearTimeout(this.timeout);
        }

        // Fire Stop Scroll Event shortly after Scrolling Stops
        this.timeout = setTimeout(this.onScrollStop.bind(this), 500);
    }

    detectResize () {
        // Get Header Height - will only exist if headerFixed preference is set to 'Enhanced'
        var $header = $('#top-header'),
            $promoBar = $header.find('#global-ticker'),
            $headerNav = $header.find('.header-nav');

        this.headerHeight = $header.outerHeight();
        this.headerNavHeight = $headerNav.outerHeight();
        this.promoBarHeight = $promoBar.length > 0 ? $promoBar.outerHeight() : 0;
        this.headerHeight = this.headerNavHeight + this.headerHeight;
        this.$html.css('--header-height', this.headerNavHeight + 'px');
        this.$html.css('--promo-bar-height', this.promoBarHeight + 'px');
        
        if ($header.hasClass('fixed-header-enhanced')) {
            // change to position static before getting offset
            $headerNav.css('position', 'static');
            this.headerNavOffsetTop = $headerNav.offset().top;
            $headerNav.css('position', '');
        }
    }

    onDirectionChange(direction) {
        this.direction = direction;

        // Update Scroll Classes
        this.$html.addClass('scroll-direction-' + this.direction);
        this.$html.removeClass('scroll-direction-' + (
            this.direction == 'down' ? 'up' : 'down'
        ));

        // Fire Custom Event `scrollDirectionChange`
        this.window.dispatchEvent(new CustomEvent('scrollDirectionChange', {
            detail: {
                direction: this.direction,
                last: this.last,
                stickyHeader: this.stickyHeader
            }
        }));
    }

    onScrollStart() {
        this.$html.addClass('is-scrolling');
        this.isScrolling = true;

        // Fire Custom Event `scrollStart`
        this.window.dispatchEvent(new CustomEvent('scrollStart', {
            detail: {
                direction: this.direction,
                last: this.last,
                stickyHeader: this.stickyHeader
            }
        }));
    }

    onScrollStop() {
        this.$html.removeClass('is-scrolling');
        this.isScrolling = false;
        this.timeout = null;

        // Fire Custom Event `scrollStop`
        this.window.dispatchEvent(new CustomEvent('scrollStop', {
            detail: {
                direction: this.direction,
                last: this.last,
                stickyHeader: this.stickyHeader
            }
        }));
    }
}

module.exports = () => {
    $(document).ready(() => {
        new ScrollDirection();
    });
};
