var $promoFullWidthDesktops = $('.product-grid .promo-full-width-desktop');
var $promoFullWidthMobiles = $('.product-grid .promo-full-width-mobile');

function resizePromoTile(promoTile, size) {
    var productTileContainer = promoTile.closest('[class*=col-'),
        hero = promoTile.querySelector('.hero');
    
    if (productTileContainer) {
        if (size === 'desktop') {
            productTileContainer.className = 'd-none d-sm-flex flex-sm-column';
            productTileContainer.classList.add('col-sm-12');
        } else if (size === 'mobile') {
            productTileContainer.className = 'd-sm-none';
            productTileContainer.classList.add('col-12');
        }

        // if PD component is hero banner, add 'no-min-height' class to fix height layout issues
        if (hero) {
            $(hero).addClass('no-min-height');
        }
    }
}

function init() {
    if ($promoFullWidthDesktops.length > 0) {
        $promoFullWidthDesktops.each(function(index) {
            resizePromoTile(this, 'desktop');
        });
    }

    if ($promoFullWidthMobiles.length > 0) {
        $promoFullWidthMobiles.each(function(index) {
            resizePromoTile(this, 'mobile');
        });
    }
}

module.exports = init();