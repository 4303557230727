'use strict';
var core = require('core/components/clientSideValidation');

core.zipCodeUppercase = function () {
    $('body').on('blur keyup change', "#zipCode, input.shippingZipCode, input.billingZipCode", function () {
        $(this).val(function (_, val) {
            return val.toUpperCase();
        });
    });
}

core.updateBandSizeValidity = function () {
    $('[data-option-id=bandSize] .options-select').on('change', function(e) {
        var target = e.target;

        if (target.value !== '') {
            target.classList.remove('is-invalid');
        }
    });
}

module.exports = core;
