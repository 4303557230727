'use strict';

/**
 * Code that makes input labels float up/down when input is focused / blured
 * ...inspired by shopify checkout UI
 */

function toggleHasInput(e) {
    var localFormGroup = e.target.closest('.float-label');

    if ( e.target.value.trim().length > 0 && !localFormGroup.classList.contains('float-label-has-input') ) {
        localFormGroup.classList.add('float-label-has-input');
    } else if (e.target.value.trim().length === 0) {
        localFormGroup.classList.remove('float-label-has-input');
    }
}

function updateLabels(floatLabels) {
    if (floatLabels && floatLabels.length > 0) {
        for (var i = 0; i < floatLabels.length; i++) {

            var formGroup = floatLabels[i],
                formEl = formGroup.querySelector('.form-control'),
                labelEl = formGroup.querySelector('.form-control-label');

            if (formEl) {

                if (labelEl) {
                    formEl.placeholder = labelEl.textContent.trim();
                }

                if (formEl.value && formEl.value.trim().length > 0) {
                    var localFormGroup = formEl.closest('.float-label');
                    localFormGroup.classList.add('float-label-has-input');
                }

                if (formEl.tagName === 'INPUT' || formEl.tagName === 'TEXTAREA') {
                    formEl.addEventListener('input', toggleHasInput);
                    formEl.addEventListener('blur', toggleHasInput);

                } else if (formEl.tagName === 'SELECT') {
                    formEl.addEventListener('change', function(e) {
                        var localFormGroup = e.target.closest('.float-label');
                        if ( e.target.value && e.target.value.length > 0 && !localFormGroup.classList.contains('float-label-has-input') ) {
                            localFormGroup.classList.add('float-label-has-input');
                        } else if (e.target.value.length === 0) {
                            localFormGroup.classList.remove('float-label-has-input');
                        }
                    });
                }
            }
        }
    }   
}

function init() {
    var floatLabels = document.querySelectorAll('.float-label');

    updateLabels(floatLabels);
}

module.exports = {
    init: init,
    updateLabels: updateLabels
}

