'use strict';

var keyboardAccessibility = require('base/components/keyboardAccessibility');
var SiteConstants = require('constants/SiteConstants');
var hoverIntent = require('jquery-hoverintent');
var menuHelpers = require('../utilities/menuHelpers');
var searchUiHelpers = require('../utilities/searchUiHelpers');

var openSelection = $dropdownLink => {
    var $dropdown = $dropdownLink.closest('.custom-dropdown');
    clearSiblings($dropdownLink);

    $dropdown.children('.dropdown-menu')
        .attr('aria-hidden', 'false')
        .find('a').removeAttr('tabindex', '-1');
    $dropdown.addClass('show');
    $dropdownLink.attr('aria-expanded', 'true');
};

var clearSelection = element => {
    var $currentPane = $(element).closest('.custom-dropdown.show');
    $currentPane.removeClass('show');

    // Adjust DOM after drawer finishes closing (mobile) or dropdown finishes hiding (desktop)
    setTimeout(() => {
        $currentPane.find('.nav-link').attr('aria-expanded', 'false');
        $currentPane.find('.dropdown-menu').attr('aria-hidden', 'true');
        $currentPane.find('.top-category').detach();
        $currentPane.find('.nav-menu').detach();

        if (!$currentPane.closest('.custom-dropdown.show').length) {
            $currentPane.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'false');
        }
    }, SiteConstants.TransitionSpeed);
};

// removes 'show' class from nested dropdowns in main mobile mene when menu is closed
function clearAllSelections($mainMenu) {
    var showEls = $mainMenu.get(0).querySelectorAll('.show');
    [].forEach.call(showEls, function(showEl) {
        var dropdownLink = showEl.querySelector('.dropdown-link');
        if (dropdownLink) {
            clearSelection(dropdownLink);
        }
    });
}

var clearSiblings = function clearSiblings($dropdownLink) {
    var $dropdown = $dropdownLink.closest('.custom-dropdown');
    var $siblings = $dropdown.siblings('.custom-dropdown.show');
    if ($siblings.length > 0) {
        $siblings.each(function() {
            var $this = $(this);
            $this.removeClass('show');
            setTimeout(() => {
                $this.find('.nav-link').attr('aria-expanded', 'false');
                $this.find('.dropdown-menu').attr('aria-hidden', 'true');
            }, SiteConstants.TransitionSpeed);
        });
    }
}

var menuAnimating = false;
var toggleDrawer = status => {
    if (!menuAnimating) {
        var $header = $('#top-header');
        var $headerBanner = $header.find('.header-banner');
        var $headerNav = $header.find('.header-nav');
        var $mainMenu = $('.burger-menu').length > 0 ? $('.burger-menu') : $header.find('.main-menu');
        var $navbar = $mainMenu.find('.navbar-nav');
        var $modalBackground = $('.modal-background');
        var $mainContent = $('#maincontent');
        var headerNavClientRect = $headerNav.get(0).getBoundingClientRect();
        var headerNavOffset = headerNavClientRect.top;

        // set menu animating to True
        menuAnimating = true;

        if (status === 'open') {
            // save windowY as style property for effectively setting users scroll position later when menu closes
            document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);
            var scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
            // reset headerNavTop
            var headerNavTop = (parseInt(scrollY) < $headerBanner.outerHeight()) ? $headerBanner.outerHeight() - window.scrollY : 0;

            // reset headerNavOffset if header has fixed-header-enhanced
            if ($mainMenu.hasClass('burger-menu')) {
                if (!$mainMenu.hasClass('burger-menu-citizen')) {
                    headerNavOffset = 0;    
                } else if (headerNavClientRect.top < 0) {
                    headerNavOffset = 0;    
                }
                $mainMenu.css('top', headerNavOffset);
            }

            // update classes after transition end on main menu
            setTimeout(function() {
                if ( $header.hasClass('fixed-header')) {
                    $headerNav.addClass('fixed-mobile-menu-open').css('top', headerNavTop);
                }

                if ($header.hasClass('fixed-header') && !$header.hasClass('transparent-header')) {
                    $mainContent.css('margin-top', $headerNav.get(0).getBoundingClientRect().height + 'px');
                }

                $('body').addClass('mobile-menu-in');
                // lock scrolling
                $('html, body').addClass('lock-scroll');
                // save ScrollY for scroll reset later when nav closes
                $('body').get(0).style.top = `-${scrollY}`;
                // set menuAnimating to false so menu can be opened again
                menuAnimating = false;
            }, SiteConstants.TransitionSpeed);

            $mainMenu
                .addClass('in')
                .attr('aria-hidden', 'false')
                .css('top', headerNavOffset)
                .height(window.innerHeight - headerNavOffset)
                .siblings().attr('aria-hidden', 'true');

            $modalBackground
                .fadeIn(SiteConstants.TransitionSpeed)
                .css('top', headerNavOffset);
            $header
                .siblings().attr('aria-hidden', 'true');
            $navbar
                .find('.nav-link').first().focus();

        } else {
            // get the scrollY saved to body to use later for updating users scroll position when menu closes
            var scrollY = $('body').get(0).style.top;
            // add class to stop scroll detection while menu animates to close
            $('body').addClass('burger-menu-closing');

            // update classes after duration for nice animation
            setTimeout(function() {
                var cssTopVal = headerNavOffset >= 0 ? headerNavOffset : 0,
                    cssTop = $mainMenu.hasClass('burger-menu-citizen') ? cssTopVal : '';

                $headerNav.removeClass('fixed-mobile-menu-open')
                    .css('top', '')
                    .addClass('transparent-nav');
                
                $mainMenu.css({
                        'top': cssTop,
                        'height': 'auto'
                    }).attr('aria-hidden', 'true')
                    .siblings().attr('aria-hidden', 'false');

                // if scroll is less than headderHeight, then update MainContent to have marin-top
                if (parseInt(scrollY) < $headerNav.get(0).getBoundingClientRect().height && $header.hasClass('fixed-header')) {
                    $mainContent.css('margin-top', '');
                }

                // reset modal top
                $modalBackground.css('top', '');

                // allow scrolling
                $('html, body').removeClass('lock-scroll');
                
                // reset window to user scrollY before menu open
                window.scrollTo(0, parseInt(scrollY || '0') * -1);

                // reset body top
                $('body').removeClass('mobile-menu-in').css('top', '');

                // update mobile menu level
                menuHelpers.clearMenuLevelToZero($mainMenu.get(0));

                // clearSelections
                clearAllSelections($mainMenu);
            }, SiteConstants.TransitionSpeed);

            // add set timeout to remove 'burger-menu-closing' class.
            // 'burger-menu-closing' prevents scroll listeners from firing.
            // this way menu closes without causing a scroll-triggered animation
            setTimeout(function() {
                $('body').removeClass('burger-menu-closing');
                // set menuAnimating to false so menu can be opened again
                menuAnimating = false;
            }, SiteConstants.TransitionSpeed + 10);
            
            $navbar.find('.nav-menu, .top-category').detach();
            $navbar.find('.thirdCatOpen').removeClass('thirdCatOpen');
            $header.siblings().attr('aria-hidden', 'false');

            $modalBackground.fadeOut(SiteConstants.TransitionSpeed);
            // trigger mainNav transitions
            $mainMenu.removeClass('in');
        }
    }
};

module.exports = () => {
    var headerBannerStatus = window.sessionStorage.getItem('hide_header_banner');
    $('.header-banner .close').not('.close-ticker').on('click', () => {
        $('.header-banner').addClass('d-none');
        window.sessionStorage.setItem('hide_header_banner', '1');
    });

    if (!headerBannerStatus || headerBannerStatus < 0) {
        $('.header-banner').removeClass('d-none');
    }

    keyboardAccessibility('.main-menu .nav-link, .main-menu .dropdown-link, .header-account-container .nav-link',
        {
            40: function(menuItem) { // down
                if (menuItem.hasClass('nav-item')) { // top level
                    $('.navbar-nav .show').removeClass('show')
                        .children('.dropdown-menu')
                        .removeClass('show');
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    menuItem.find('ul > li > a').first().focus();
                } else {
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    if (!(menuItem.next().length > 0)) { // if this is the last menuItem
                        menuItem.parent().parent().find('li > a').first().focus(); // set focus to the first menuitem
                    } else {
                        menuItem.next().children().first().focus();
                    }
                }
            },
            39: function(menuItem) { // right
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.next().children().first().focus();
                } else if (menuItem.hasClass('dropdown')) {
                    menuItem.addClass('show').children('.dropdown-menu').addClass('show');
                    $(this).attr('aria-expanded', 'true');
                    menuItem.find('ul > li > a').first().focus();
                }
            },
            38: function(menuItem) { // up
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                } else if (menuItem.prev().length === 0) { // first menuItem
                    menuItem.parent().parent().removeClass('show')
                        .children('.nav-link')
                        .attr('aria-expanded', 'false');
                    menuItem.parent().children().last().children().first().focus(); // set the focus to the last menuItem
                } else {
                    menuItem.prev().children().first().focus();
                }
            },
            37: function(menuItem) { // left
                if (menuItem.hasClass('nav-item')) { // top level
                    menuItem.removeClass('show').children('.dropdown-menu').removeClass('show');
                    $(this).attr('aria-expanded', 'false');
                    menuItem.prev().children().first().focus();
                } else {
                    menuItem.closest('.show').removeClass('show')
                        .closest('li.show').removeClass('show')
                        .children().first().focus().attr('aria-expanded', 'false');
                }
            },
            27: function(menuItem) { // escape
                var parentMenu = menuItem.hasClass('show')
                    ? menuItem
                    : menuItem.closest('li.show');
                parentMenu.children('.show').removeClass('show');
                parentMenu.removeClass('show').children('.nav-link')
                    .attr('aria-expanded', 'false');
                parentMenu.children().first().focus();
            }
        },
        function () {
            return $(this).parent();
        }
    );

    //if only search icon is visible, show the input on focus
    $( ".search-field" ).focus(function() {
        $(this).closest('.site-search').addClass('expanded');
    });

    // init new menu
    menuHelpers.init();
    // set burger top
    menuHelpers.setBurgerMenuTop();

    // Custom dropdown behaviors for top menu
    var $dropdownMenu = $('.custom-dropdown:not(.disabled) [data-toggle="dropdown"]');
   
    $dropdownMenu.on('click', event => {
        event.stopPropagation(); // Prevent Bootstrap dropdown click events

        var isInBurger = $(event.target).closest('.burger-menu'),
            trigger = event.target.classList.contains('.custom-dropdown') ? event.target : event.target.closest('.custom-dropdown');

        // dispatch new menu update event
        menuHelpers.dispatchCustomMenuUpdateEvent(trigger);

        if (window.isMobile() || isInBurger.length > 0) {
            // Drawer behavior on mobile
            event.preventDefault();
            var $dropdownLink = $(event.target);
            var $dropdown = $dropdownLink.closest('.custom-dropdown');

            if ($dropdownLink.data('disable-subcategories-display-in-mobile')) {
                location.href = $(event.target).attr('href');
                return;
            }
            if ($dropdown.hasClass('show')) {
                clearSelection($dropdown.get(0));
            } else {
                openSelection($dropdownLink);
            }
        } else {
            // Restore link behavior on desktop
            var link = $(event.target).attr('href');

            if (link) {
                location.href = $(event.target).attr('href');
            }
        }
    });

    //Megamenu Mega-left menu
    $('.mega-left-menu-link').hoverIntent( function() {
            var menuName = this.id,
                menuRight = $('#' + menuName + '-right'),
                menuLeft = $(this).parent().parent(),
                menuRightParent = menuLeft.next();

            if (!window.isMobile()) {
                // clear classes
                menuLeft.find('.active').removeClass('active');
                menuRightParent.removeClass('has-sub-asset');
                menuRightParent.find('.active-list').removeClass('active-list');
                // add classes
                $(this).addClass('active');
                menuRight.parent().addClass('active-list');
                // add a class to the parent Ul if a third level asset is displayed; css-hides the top level asset
                if (menuRight.parent().next().hasClass('sub-asset-1') || menuRight.parent().next().hasClass('sub-asset-2')) {
                    menuRightParent.addClass('has-sub-asset');
                }
            }
        },
        50
    );

    // Desktop - open menu using hoverIntent to prevent unintentional opening
    $dropdownMenu.hoverIntent({
        over: event => {
            var isInBurger = $(event.target).closest('.burger-menu');
             // close search if it is open
             searchUiHelpers.closeDesktopSearch();
            
            if (!window.isMobile() && isInBurger.length === 0) {
                var eventElement = event.target;
                // Close all dropdowns
                $('.navbar-nav > li').each((index, element) => {
                    if (!$.contains(element, eventElement)) {
                        var $navItem = $(element);
                        $navItem.find('.show').each(() => {
                            clearSelection(element);
                        });
                        if ($navItem.hasClass('show')) {
                            $navItem.removeClass('show');
                            $navItem.children('ul.dropdown-menu').removeClass('show');
                            $navItem.children('.nav-link').attr('aria-expanded', 'false');
                        }
                    }
                });
                // Open current dropdown
                $(eventElement).parent().addClass('show');
                $(eventElement).siblings('.dropdown-menu').addClass('show').attr('aria-hidden', 'false');
                $(eventElement).attr('aria-expanded', 'true');
            }
        },
        out: () => {
            // this 'out' handler is left empty, since it's triggered from parent, handled in mouseleave event below
        },
        interval: 50
    });

    // Desktop - close menu on mouseleave
    $dropdownMenu.parent().on('mouseleave', event => {
        var isInBurger = $(event.target).closest('.burger-menu');

        if (!window.isMobile() && isInBurger.length === 0) {
            var $dropdown = $(event.currentTarget);
            // Close current dropdown
            $dropdown.removeClass('show');
            $dropdown.children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
            $dropdown.children('.nav-link').attr('aria-expanded', 'false');
        }
    });

    // add burger toggler to original navbar-toggler click handler
    $('.navbar-toggler, .burger-menu-toggler').click(event => {
        event.preventDefault();
        var menuToggle = $(event.target).hasClass('burger-menu-toggler') || event.target.closest('.burger-menu-toggler') || event.target.closest('.burger-menu') ? '.burger-menu-toggler' : '.navbar-toggler';

        if ($('body').hasClass('mobile-menu-in')) {
            toggleDrawer('close');
            $(menuToggle).removeClass('open', menuToggle);
            $('.header-nav').addClass('transparent-nav');
        } else {
            toggleDrawer('open');
            $(menuToggle).addClass('open');
            $('.header-nav').removeClass('transparent-nav');
        }
    });

    $('html').on('click', '.mobile-menu-in .modal-background', function() { 
        var $menuToggle = $('.navbar-toggler, .burger-menu-toggler');
        toggleDrawer('close');
        $menuToggle.removeClass('open');
        $('.header-nav').addClass('transparent-nav');
    });

    // Prevent iOS from scrolling to bad position on search field input focus
    $('.search-mobile .search-field').focus(event => {
        if (window.isMobile()) {
            var currentScrollPosition = $('html').scrollTop();
            setTimeout(() => {
                $('html').scrollTop(currentScrollPosition);
                $('.modal-background').show();
            }, SiteConstants.TransitionSpeed);
        }
    });

    // on menuLevelUpdate - see if back btn was clicked, if so , run clear selection code on menu navigation
    document.addEventListener('menuLevelUpdate', function(e) {
        var isBackBtn = e.detail.trigger.classList.contains('active-category-back-btn') || e.detail.trigger.closest('.active-category-back-btn'),
            localNavbar = e.detail.trigger.closest('.navbar');

        if (isBackBtn) {
            var localCurrentShowEl = localNavbar.querySelector('[data-menu-level="' + e.detail.oldLevel + '"].show');

            if (localCurrentShowEl) {
                var dropdownLink = localCurrentShowEl.querySelector('.dropdown-link');
                if (dropdownLink) {
                    clearSelection(dropdownLink);
                }   
            }
        } 
    });
};
