'use strict';

var getTotalScroll = require('../product/scrollToElement').getTotalScroll;

var showDownDownArrows = document.querySelectorAll('[data-show-down-down-arrow]');

function scrollToNextSection(showDownDownArrow) {
    // use data component id & jquery visible to see what is the next visible component
    // next visible section could vary depending on screeensize
    var closestMainParent = $(showDownDownArrow).closest('#maincontent > .page-designer > .experience-main > .experience-component'),
        targetEl = closestMainParent[0].nextSibling,
        totalScroll;

    if (targetEl) {
        totalScroll = getTotalScroll(targetEl);
        // animate scroll to element
        $("html, body").animate({
            scrollTop: totalScroll,
            duration: 500,
        }, "easein");
    }
}

function init() {
    if (showDownDownArrows.length > 0) {
        [].forEach.call(showDownDownArrows, function(showDownDownArrow) {

            showDownDownArrow.addEventListener('click', function(e) {
                e.preventDefault();
            
                // get next visible section
                scrollToNextSection(showDownDownArrow);
            });
        });
    }
}

module.exports = {
    init: init
}

