'use strict';

// observer function for determining when sticky scroll enter/leave viewport
// dispatch custom enter/exit events to hook window scroll functionality into
const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
        if (entry.isIntersecting) {
            document.dispatchEvent(new CustomEvent("forcedScrollEnterViewport", { detail: entry.target }));
        } else {
            document.dispatchEvent(new CustomEvent("forcedScrollExitViewport", { detail: entry.target }));
        }
    });
}, { threshold: 0 });

// helper function to get scroll bar width
function getScrollbarWidth() {
    return window.innerWidth - document.documentElement.clientWidth;
}

// helper function to get talles child in element   
function getTallestChildHeight(parent) {
    let maxHeight = 0;
    [].forEach.call(parent.children, (child) => {
        const height = child.offsetHeight;
        if (height > maxHeight) {
            maxHeight = height;
        }
    });

    return maxHeight;
}

// helper fucntion for getting the furthest postioined absolue/relative anscestor
// ensures that the offsetTop value we calculate is corrrect
function getFurthestPositionedAncestor(element) {
    // let furthest = null;
    let furthest = 0;

    while (element && element !== document.body) {
        const style = window.getComputedStyle(element);
        if (style.position === "relative" || style.position === "absolute") {
            furthest = element; // Update furthest found ancestor
        }
        element = element.parentElement;
    }

    return furthest; // Return the last valid ancestor found
}

// sets up sticky scroll container height large enough to scroll to the 
// max transform level
function setStickyHeight(sticky) {
    let scroll_section = sticky.querySelector('.scroll_section'),
        sticky_parent = sticky.closest('.sticky_parent'),
        positionedAncestor = getFurthestPositionedAncestor(sticky),
        axis = sticky.dataset.axis,
        offsetArea = axis === 'horizontal' ? Math.abs(scroll_section.clientWidth - sticky.clientWidth) + getScrollbarWidth() : Math.abs(scroll_section.clientHeight - sticky.clientHeight),
        stickyHeight = offsetArea + window.innerHeight,
        scrollSectionHeight,
        marginBottom,
        max;
    
    // set offsettop value - to be used later via transformScrollSecdtion Fn
    if (positionedAncestor) {
        sticky.setAttribute('data-scroll-offset-top', positionedAncestor.offsetTop);
    } else {
        sticky.setAttribute('data-scroll-offset-top', sticky.parentElement.offsetTop);
    }
    
    // nudge up content same amount as marginBottom for more dynamic end of scrolling
    if (axis === 'horizontal') {
        scrollSectionHeight = getTallestChildHeight(scroll_section);
        marginBottom = window.innerHeight - scrollSectionHeight - parseInt(window.getComputedStyle(sticky).top);

        stickyHeight = stickyHeight - marginBottom;
        // align next sibling content by updating marginBottom to negative / pulling next sibing close to the element
        sticky.style.marginBottom = (marginBottom * -1) + 'px';
    }

    // set sticky_parent height
    sticky_parent.style.height = stickyHeight + 'px';       
    // calc max
    max = axis === 'horizontal' ? (scroll_section.clientWidth - sticky.clientWidth) + getScrollbarWidth() : (scroll_section.clientHeight - sticky.clientHeight);
    // set max on section itselr
    sticky.dataset.scrollMax = max;
}

 // setup sticky elements
function setupStickyElements(stickySections) {
    stickySections.forEach((sticky) => {
        let forced_slides = sticky.querySelectorAll('.forced-slide'),
            gutter = sticky.dataset.gutter,
            axis = sticky.dataset.axis,
            minSize = sticky.dataset.minWidth;
        
        if (axis === 'horizontal') {
            // setup slider styles
            [].forEach.call(forced_slides, function(forced_slide) {
                forced_slide.style.minWidth = minSize + 'px';
                forced_slide.style.marginLeft = gutter + 'px';
                forced_slide.style.marginRight = gutter + 'px';
            }); 
        } else {
            // setup slider styles
            [].forEach.call(forced_slides, function(forced_slide) {
                forced_slide.style.minHeight = minSize + 'px';
                forced_slide.style.minWidth = minSize + 'px';
                forced_slide.style.marginTop = gutter + 'px';
                forced_slide.style.marginBottom = gutter + 'px';
            }); 
        }

        setStickyHeight(sticky);
    });
}

// runs when elements is being transformed on scroll
function transformScrollSection(sticky) {
    const scrollSection = sticky.querySelector('.scroll_section');

    let diff = window.scrollY - sticky.dataset.scrollOffsetTop,
        transformStyle;
    
    // ensure diff is never less than 0, or greater than the max
    diff = diff < 0 ? 0 : diff > Math.abs(sticky.dataset.scrollMax) ? Math.abs(sticky.dataset.scrollMax) : diff;
    // multiplay diff by dir
    if (diff !== 0) {
        diff = sticky.dataset.scrollDirection * diff !== NaN ? Math.round(sticky.dataset.scrollDirection * diff) : -1;
    }
    // build transform style
    transformStyle = sticky.dataset.axis === 'horizontal' ? `translate3d(${diff}px, 0, 0)` : `translate3d(0, ${diff}px, 0)`;
    // set scroll section style
    scrollSection.style.transform = transformStyle;
}


// Initialize on page load in case the element is already sticky
function init() {
    const stickySections = [...document.querySelectorAll('.sticky')];
    // use object to hold sticky Trasform fns 
    // use later for naming scroll event listener to add/remove
    const stickyTransformFunctionLibrary = {};
    
    if (stickySections.length > 0) {
        // setup sticky elements
        setupStickyElements(stickySections);
        // observe sticky elements for enter/exit viewport
        stickySections.forEach((sticky) => {
            observer.observe(sticky);
        });

        // add events to load, 
        window.addEventListener('load', () => {
            setupStickyElements(stickySections);
        });
        
        window.addEventListener('resize', () => {
            setupStickyElements(stickySections);
        });

        document.addEventListener('forcedScrollEnterViewport', (e) => {
            let target = e.detail,
                fnName = 'transform' + Array.prototype.indexOf.call(stickySections, target);

            // check if fnName exists in our stickyTransformFunctionLibrary
            if (!(fnName in stickyTransformFunctionLibrary)) {
                // if fnName not in library, then create new transformScrollFunct that is bound to current target
                // and store it in our sticyTransformFunctionLibrary
                stickyTransformFunctionLibrary[fnName] = transformScrollSection.bind(null, target);
            }

            // update sticky heights as numbers could have changed
            setStickyHeight(target);
            // only apply fn if scroll section is big enough (bigger than window width) or if setion is a veritcal slider
            if ((target.querySelector('.scroll_section').clientWidth > target.parentElement.clientWidth) || target.dataset.axis === 'vertical') {
                window.addEventListener('scroll', stickyTransformFunctionLibrary[fnName], false);        
            }
        });

        document.addEventListener('forcedScrollExitViewport', (e) => {
            let target = e.detail,
                fnName = 'transform' + Array.prototype.indexOf.call(stickySections, target);

            window.removeEventListener('scroll', stickyTransformFunctionLibrary[fnName], false);     
        });
    }
}

module.exports = init();


