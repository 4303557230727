'use strict';
var modal = require('core/components/modal');

function promoModal() {
    $('body').on('click', '.custom-promo-modal', function (e) {
        e.preventDefault();
        var details = $(this).attr('data-details');
        var title = $(this).attr('data-title');
        var dialogClasses = $(this).attr('data-dialog-classes');
        var closeButtonText = $(this).attr('data-close-button-text');

        modal.getModalHtmlElement('customPromoModal', dialogClasses);
        var $modal = $('#customPromoModal');
        $modal.find('.modal-body').empty();
        $modal.find('.modal-body').html(details);
        $modal.find('.modal-header .close .sr-only').text(closeButtonText);
        $modal.modal('show');
        $modal.find('.modal-header').prepend('<div class="modal-title">' + title + '</div>');
    });
}

module.exports = {
    promoModal: promoModal
};