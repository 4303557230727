'use strict';

var SiteConstants = require('constants/SiteConstants');

/**
 * 
 * @param {*} targetEl -  Element to calculate window scroll distance 
 * @returns 
 */
function getTotalScroll(targetEl) {
// calculate scrolls
    var targetElY = targetEl.getBoundingClientRect().y,
        isHeaderFixed = $('.fixed-header').length > 0,
        fixedHeaderHeight = isHeaderFixed ? $('.fixed-header').get(0).getBoundingClientRect().height : 0,
        isEnhancedHeaderScrolledUp = $('.scroll-direction-up .fixed-header-enhanced').length > 0,
        $stickyBar = $('.addtocart-sticky-bar'),
        stickyBarHeight = isHeaderFixed ? document.querySelector('#header-nav').getBoundingClientRect().height : 0,
        fixedAddToCartHeight = $stickyBar.length > 0 ? $stickyBar.get(0).getBoundingClientRect().height : 0,
        offset = ($stickyBar.length > 0 && $stickyBar.get(0).getBoundingClientRect().y <= stickyBarHeight) ? fixedHeaderHeight + fixedAddToCartHeight : fixedHeaderHeight,
        totalScroll;
    
    offset += isEnhancedHeaderScrolledUp && window.innerWidth > SiteConstants.BreakpointSizes.md ? fixedAddToCartHeight : 0;
    totalScroll = targetElY + window.scrollY - offset;

    return totalScroll;
}

// helperfunction for calculating Header height
function getScrollToOffset(reviewsContainer, closestCollapsed) {
    var headerOffset = $('header.fixed-header').length > 0 ? $('#header-nav').outerHeight() : 0,
        miniCartOffset = $('#addtocart-sticky-bar').length > 0 ? $('#addtocart-sticky-bar').outerHeight() : 0,
        scrollToContainer = closestCollapsed ? closestCollapsed : reviewsContainer,
        scrollOffset = parseInt($(scrollToContainer).offset().top - headerOffset - miniCartOffset, 10);

    return scrollOffset;
}

var scrollToLink = function scrollToLink(e) {
    e.preventDefault();
    // calculate scrolls
    var targetEl = document.querySelector($(this).attr('data-scroll-to')),
        totalScroll = getTotalScroll(targetEl);

    // active class toggles
    $('.internal-links-nav-link.active').removeClass('active');
    $(this).closest('.internal-links-nav-link').addClass('active');
    
    // animate scroll to element
    $("html, body").animate({
        scrollTop: totalScroll,
        duration: 500
    });

    // test if target is a 'PDP accordion', if so, open it
    var pdpCollapsibleContent = targetEl.querySelector('.pdp-collapsible-content:not(.active)');
    if (pdpCollapsibleContent) {
        var pdpCardTitle = targetEl.querySelector('.card-title');
        if (pdpCardTitle !== null) {
            $(pdpCardTitle).trigger('click');
        }
    }    
}

function executeReviewScroll() {
    var reviewsContainer = document.querySelector('[data-reviews-container]'),
        closestCollapsed,
        scrollOffset;

    if (reviewsContainer) {
        closestCollapsed = reviewsContainer.closest('.collapsible-content-group');
        scrollOffset = getScrollToOffset(reviewsContainer, closestCollapsed);    

        // if collapsed is not visible, manually expand it
        if (closestCollapsed) {
            if (!$(closestCollapsed.querySelector('.content')).is(':visible')) {
                $(closestCollapsed).addClass('active').find('.card-title[aria-expanded="false"]').attr('aria-expanded', 'true');
            }
        }

        // active class toggles
        $('.internal-links-nav-link.active').removeClass('active');
        // only add active to pdp-product-reviews
        $('.internal-links-nav-link[data-scroll-to="#pdp-product-reviews"]').addClass('active');

        $('html,body').animate({
            scrollTop: scrollOffset
        });
    }
}

function init() {
    var $scrollToElements = $('[data-scroll-to]');

    $.each($scrollToElements, function() {
        $(this).on('click', scrollToLink);
    });

    $('body').on('click', '.reviews-star-average-rating .rating-star, .scroll-to-review', executeReviewScroll);

    document.addEventListener('reviewSortUpdate', executeReviewScroll);
}

module.exports.init = init;
module.exports.getTotalScroll = getTotalScroll;