'use strict';

/**
 * Client side js that triggers video play on hover if the video has not been previously
 * played or muted. Video.currentTime must be 0 for the hover event to trigger play()
 * This works with the video html set up by the existing video js scripts
 */
function init() {
    var $pdpVideos = $('[data-video-play-on-over]');

    $pdpVideos.each(function() {
        var vid = this;
        var vidContainer= vid.closest('.video-container');

        if (vidContainer) {
            var vidThumbnail = vidContainer.querySelector('.video-thumbnail');

            if (vidThumbnail) {
                $(vidThumbnail).on('mouseover', function(e) {
                    if (vid.paused && vid.currentTime === 0 && !vid.ended) {
                        // ensure video is muted so it can be played automatically
                        // without user interaction
                        vid.muted = true;
                        // trigger thumbnail to ensure video js triggers properly
                        if (vidThumbnail) {
                            $(vidThumbnail).trigger('click');
                        }
                    }
                });
            }
        }
    });
}

module.exports = init();