'use strict';

var tinySlider = require('tiny-slider/src/tiny-slider');

var $giftCertificateForm = $('.GiftCertificateForm');
var $giftCardSlider = $('#gift-card-slider');

// populate form helper
function populateGiftCardForm($form, color) {
    var $gcColor = $form.find('#color');

    $gcColor.get(0).value = color;
    $gcColor.get(0).textContent = color;
}

/**
 * init - adds handlers to slider transtionStart event that populate the gc form with 
 * card color.
 * Also, adds handlers to thumb/swatch click events to trigger slider change, which in 
 * turn triggers the form update
 */
function init() {
    if ($giftCertificateForm.length > 0) {
        var $giftAmount = $giftCertificateForm.find('#giftAmount');
        var $formAmount = $giftCertificateForm.find('#amount');

        // on gift amont select change, update the form amount value
        $giftAmount.on('change', function(e) {
            $formAmount.get(0).value = $giftAmount.val();
            $formAmount.get(0).textContent = $giftAmount.val();
        });
        
        if ($giftCardSlider.length > 0 && $giftCardSlider.get(0).children.length > 0) {
            // init gcSlider
            var giftCardSlider = tinySlider.tns({
                container: '#gift-card-slider',
                items: 1,
                slideBy: 'page',
                autoplay: false,
                gutter: 0,
                edgePadding: 0,
                onInit: function(slider) {
                    $(slider.container).addClass('gift-card-slider-initialized');
                }
            });

            giftCardSlider.events.on('transitionStart', function() {
                // get tiny slider info obj
                var sliderInfo = giftCardSlider.getInfo();
                // get active swatch, thumb items
                var activeSwatch = $('.gift-card-swatch-item').get(sliderInfo.displayIndex - 1);
                var activeThumb = $('.gift-card-thumb-item').get(sliderInfo.displayIndex - 1);
                
                // save color value that we will use to populate gc form
                var cardColor = $(activeThumb).data('giftCardThumb');

                // remove previous active class on all thumbs/swatch
                $('.gift-card-swatch-item, .gift-card-thumb-item').removeClass('active');
                // toggle active class on current thumb/swatch
                $(activeThumb).addClass('active');
                $(activeSwatch).addClass('active');

                // populate gc form with color
                populateGiftCardForm($giftCertificateForm, cardColor);

            });

            // on thumb/swatch click, trigger slider that will update form
            $('.gift-card-swatch-item, .gift-card-thumb-item').on('click', function(e) {
                e.preventDefault();
                giftCardSlider.goTo($(this).index());
            });
        }
    }
}

module.exports = {
    init
}