'use strict';

var SiteConstants = require('constants/SiteConstants');
const debounce = require('lodash/debounce');
import * as ScrollMagic from "scrollmagic";
import { TweenMax, TimelineMax } from "gsap";
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);


// PD component animations: horizontal scroll and slide
var $elementHorizontalScroll = $('.element-horizontal-scroll');
var elementHorizontalScroll = $elementHorizontalScroll.length ? true : false;

var $elementHorizontalSliderWithScroll = $('.element-horizontal-scroll-with-slider');
var elementHorizontalSliderWithScroll = $elementHorizontalSliderWithScroll.length ? true : false;

// PDP Real Time Watch Animation
var $elementRealTimeImage = $('.element-real-time-watch');
var elementRealTimeImage = $elementRealTimeImage.length ? true : false;

if (elementRealTimeImage) {
    // prevents animation from "spinning catch-up" effect on page load/refresh
    window.requestAnimFrame = (function(){
        return  window.requestAnimationFrame       ||
                window.webkitRequestAnimationFrame ||
                window.mozRequestAnimationFrame    ||
                function( callback ){
                    window.setTimeout(callback, 1000 / 60);
                };
    })();

    function drawDate() {
        var dateElement = document.getElementById("dateNumber");
        var now = new Date();
        dateElement.innerHTML = now.getDate();
    }

    function drawHands() {
        var hoursElement = document.getElementById("hourHand");
        var minutesElement = document.getElementById("minuteHand");
        var secondsElement = document.getElementById("secondHand");
        var now = new Date(),
            midnight = new Date(now.getFullYear(),now.getMonth(),now.getDate(),0,0,0),
            diffInMilliseconds = (now.getTime() - midnight.getTime()),
            hours = (diffInMilliseconds/(1000*60*60)),
            minutes = (hours*60),
            seconds = (minutes*60);

        secondsElement.style.transform = "rotate(" + (seconds * 6) + "deg)";
        hoursElement.style.transform = "rotate(" + (hours * 30) + "deg)";
        minutesElement.style.transform = "rotate(" + (minutes * 6) + "deg)";
    }

    drawDate();
    (function loop(){
        requestAnimFrame(loop);
        drawHands();
    })();
}

if (elementHorizontalScroll || elementHorizontalSliderWithScroll) {
    var controller = new ScrollMagic.Controller();
    var scenes = [];

    function setAnimationDuration() {
        var duration = {};
        duration.slider = 2000;
        duration.scroll = 2000;
        duration.scene = 2000;
        if ($(window).width() < SiteConstants.BreakpointSizes.md) {
            duration.slider = 1000;
            duration.scroll = 2000;
            duration.scene = 3000;
        }
        return duration;
    };

    var animationDuration = setAnimationDuration();

    function createHorizontalSliderWithScroll() {
        $elementHorizontalSliderWithScroll.each(function() {
            var $sliderElement = $(this).closest('.experience-component');
            $sliderElement.parent().addClass('element-horizontal-slide-track');

            var $sliderContainer = $sliderElement.closest('.experience-commerce_layouts-1column');

            var sliderElement = $sliderElement[0];
            var sliderContainer = $sliderContainer[0];
            var scrollElementTrack = $(this).find('.element-horizontal-scroll-track')[0];
            var scrollTransitionalText = $(this).parent().parent().parent().find('.slider-transition-animation').children().find('.hero-text')[0];

            var action = new TimelineMax()
                .add(TweenMax.to(scrollTransitionalText, animationDuration.slider, {left:'-90%'}), "first")
                .add(TweenMax.fromTo(sliderElement, animationDuration.slider, {left:"100%"}, {left:0}), "first")
                .add(TweenMax.to(scrollElementTrack,animationDuration.scroll,{right:'0'}), "second");

            var scene = new ScrollMagic.Scene({
                triggerElement: sliderContainer,
                triggerHook: 0,
                duration: animationDuration.scene
            })
                .setPin(sliderContainer)
                .setTween(action)
                .addTo(controller);

            scenes.push(scene);
        });
    }

    function createHorizontalScroll() {
        $elementHorizontalScroll.each(function() {
            var scrollElement = $(this)[0];
            var scrollElementTrack = $(this).find('.element-horizontal-scroll-track')[0];
            var action = new TimelineMax();
                action.to(scrollElementTrack,animationDuration.scroll,{right:'0'});

            var scene = new ScrollMagic.Scene({
                triggerElement: scrollElement,
                triggerHook: 0,
                duration: animationDuration.scene
            })
                .setPin(scrollElement)
                .setTween(action)
                .addTo(controller);

            scenes.push(scene);
        });
    }

    if (elementHorizontalSliderWithScroll) {
        createHorizontalSliderWithScroll();
    } else if (elementHorizontalScroll) {
        createHorizontalScroll();
    }

    $(window).on('resize', debounce(function () {
        if(!isMobile) {
            for (var i = 0; i < scenes.length; i++) {
                scenes[i].destroy(true);
                // the destroy above doesn't, so have to remove this manually ...
                $('.element-horizontal-scroll-track').removeAttr('style');
            }

            animationDuration = setAnimationDuration();

            if (elementHorizontalSliderWithScroll) {
                createHorizontalSliderWithScroll();
            } else if (elementHorizontalScroll) {
                createHorizontalScroll();
            }
        }
    }, 500))

    window.addEventListener("orientationchange", function() {
        for (var i = 0; i < scenes.length; i++) {
            scenes[i].destroy(true);
            // the destroy above doesn't, so have to remove this manually ...
            $('.element-horizontal-scroll-track').removeAttr('style');
        }

        animationDuration = setAnimationDuration();

        if (elementHorizontalSliderWithScroll) {
            createHorizontalSliderWithScroll();
        } else if (elementHorizontalScroll) {
            createHorizontalScroll();
        }
      }, 500)
}
