'use strict';

var recaptcha = require('core/components/recaptcha');

function init() {
    const form = document.querySelector('.flagship-form');

    if (form) {
        const formInputs = form.querySelectorAll('.form-control');
        const flagshipError = form.querySelector('.flagship-error');

        $(form).on('submit', function(e) {
            e.preventDefault();

            const form = e.target;
            const $modalContent = $(form).closest('.modal-content');
        
            recaptcha.check(e, {
                url: form.action,
                data : $(form).serialize(),
                beforeSend: () => $modalContent.spinner().start(),
                success: function(data) {
                    if (data.success) {
                        const flagshipSuccess = document.querySelector('.flagship-success');

                        form.classList.add('d-none');

                        flagshipSuccess.classList.remove('d-none');
                        flagshipSuccess.classList.add('show-flagship-success');
                    } else {
                        if ('error' in data) {
                            flagshipError.innerHTML = data.error;
                            flagshipError.classList.remove('d-none');
                        }
                    }
                },
                error: function(e) {
                    console.log('error:', e);
                },
                complete: function(e) {
                    $modalContent.spinner().stop();
                }
            });    
        });
        
        [].forEach.call(formInputs, function(formInput) {
            // if element is valid after updates, and input was previously invalid, remove 'is-invalid' test
            formInput.addEventListener('blur', function(e) {
                const target = e.target;

                if (target.classList.contains('is-invalid') && target.checkValidity()) {
                    target.classList.remove('is-invalid'); 
                }   
            });
        });
    }
}

module.exports.init = init;