var main = require('integrations/main');
const forcedSlider = require('./components/forcedSlider');

Object.assign(main.baseFiles, {
    countrySelector: require('org_citizen/components/countrySelector'),
    giftCertificate: require('org_citizen/giftCertificate/giftCertificate').init,
    slider: require('./components/slider').init,
    modal: require('org_citizen/components/modal').init,
    quickView: require('org_citizen/product/quickView'),
    cart: require('org_citizen/cart/cart').init,
    video: require('org_citizen/components/video'),
    backInStockNotification: require('org_citizen/components/backInStockNotification'),
    promoModal: require('org_citizen/components/promoModal'),
    showMoreText: require('org_citizen/components/showMoreText'),
    showOnHover: require('org_citizen/components/showOnHover'),
    menu: require('org_citizen/components/menu'),
    clientSideValidation: require('org_citizen/components/clientSideValidation'),
    base: require('org_citizen/product/base'),
    animations: require('org_citizen/components/animations'),
    referrer: require('org_citizen/cj/referrer'),
    watchCalibers: require('org_citizen/components/watchCalibers'),
    search: require('org_citizen/components/search'),
    header: require('org_citizen/components/header'),
    miniCart: require('org_citizen/components/miniCart').init,
    scroll: require('./utilities/scroll'),
    tabs: require('org_citizen/components/tabs'),
    promoFullWidth: require('org_citizen/components/promoFullWidth'),
    playVideoOnHover: require('org_citizen/components/playVideoOnHover'),
    closableColumn: require('org_citizen/components/closableColumn'),
    giftCard: require('org_citizen/components/giftCard'),
    showDownDownLink: require('org_citizen/components/showDownDownLink'),
    collapsibleItem: require('org_citizen/components/collapsibleItem'),
    floatLabels: require('org_citizen/components/floatLabels'),
    inputMask: require('org_citizen/components/inputMask'),
    toggleElementHeight: require('org_citizen/components/toggleElementHeight'),
    setupProductMarketingVideoModals: require('org_citizen/components/video/videoHelpers').setupProductMarketingVideoModals,
    einsteinRecs: require('./components/einsteinRecs').init,
    forcedSlider: require('./components/forcedSlider')
});

module.exports = main;
