/**
 * sliderHelpers
 * This code ensures that if a product tile is present in a slider,
 * then all of the slides height are re-adjusted to that of the talles product tile
 */

var debounce = require('lodash/debounce');

function getMaxSlideHeight($productTiles) {
    var maxHeight = 0;

    $productTiles.each(function() {
        var thisH = $(this).height();

        if (thisH > maxHeight) {
            maxHeight = thisH;
        }
    });

    return maxHeight;
}

function setSliderHeight($slides, action, maxHeight) {
    if (action === 'reset') {
        $($slides.parent()).removeClass('product-tile-slider').height('auto');
    } else if (action === 'set') {
        $($slides.parent()).addClass('product-tile-slider').height(maxHeight);
    }
}

function equalizeSlideHeights(slider) {
    var $productTiles = $(slider).find('.product');
    var $slides =  $(slider).find('.slide');
    var maxHeight = 0;

    setSliderHeight($slides, 'reset', null);
    maxHeight = getMaxSlideHeight($productTiles);
    
    if (maxHeight !== 0) {
        setSliderHeight($slides, 'set', maxHeight);
    }
}

function initProductSlideEqualizeHeights() {
    $('body').on('slider:init.sliderEvents', function (e, sliderContainer) {
        var productTileSlider = $(sliderContainer).find('.slider');

        if ($(productTileSlider).find('.product').length > 0) {
            productTileSlider.imagesLoaded().done(() => {
                equalizeSlideHeights(productTileSlider);

                window.addEventListener('resize', debounce(function () {
                    equalizeSlideHeights(productTileSlider);
                }, 500));
            });
        }
    });
}

/**
 * moveTickerBannerModals
 * @param {*} slider - jQuery Obj to search for modal elements
 * 
 * Modals opened from the header are clipped due to z-index and overflow styles of header
 * they must be moved outside the header in order to render unclipped
 */
function moveTickerBannerModals(slider) {
    var $modals = $(slider).find('.modal');
    var $body = $('body');
    $modals.each(function() {
        $(this).appendTo($body);
    });
}

module.exports = {
    initProductSlideEqualizeHeights: initProductSlideEqualizeHeights,
    moveTickerBannerModals: moveTickerBannerModals 
}
