'use strict';

function setCjEvent() {
    if ('cjSetReferrerUrl' in window.CachedData.urls && window.CachedData.urls.cjSetReferrerUrl){
        const url = window.CachedData.urls.cjSetReferrerUrl;
        const urlParams = new URLSearchParams(window.location.search.toLowerCase());
        const cjevent = urlParams.get('cjevent');
    
        $.ajax({
            url,
            data: { cjevent },
            error: () => window.console.error('An error occured while setting the cj event')
        });
    }
};

module.exports = {
    setCjEvent: setCjEvent
};
